import { ReactComponent as Plus } from "../icons/Plus.svg";
import { themeColor } from "../utils";

export const BtnOutline = ({
  content,
  color = "primary",
  className = "",
  ...props
}: {
  content: string;
  color?: themeColor | "white";
} & React.ComponentProps<"button">) => {
  const tailwindClasses = getTailwindClassesFromColor(color);
  return (
    <button
      className={`${tailwindClasses} inline-block rounded-md border-2 bg-transparent px-3 py-2 transition hover:text-white disabled:border-gray-300/50 disabled:bg-gray-300/50 disabled:text-gray-500 ${className}`}
      {...props}
    >
      {content}
    </button>
  );
};

export const BtnOutlinePlus = ({
  color = "primary",
}: {
  color?: themeColor;
}) => {
  const tailwindClasses = getTailwindClassesFromColor(color);
  return (
    <button
      className={`${tailwindClasses} h-10 rounded-md border-2 bg-transparent p-2 leading-none transition hover:text-white`}
    >
      <Plus />
    </button>
  );
};

const getTailwindClassesFromColor = (color: themeColor | "white"): string =>
  `border-${color} text-${color} hover:bg-${color}`;
