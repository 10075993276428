import { Link } from "react-router-dom";

import Footer from "../components/Footer";
import Nav from "../components/Nav";

import { ReactComponent as ArrowInCircle } from "../icons/arrowInCircle.svg";
import background from "../images/top-section-bg.webp";

const Partners = () => (
  <div className="flex min-h-screen flex-col">
    <Nav />
    <div className="-mt-20"></div>
    <div
      className="flex flex-1 flex-col items-center bg-cover text-white"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="grid min-h-[40vh] grid-rows-[1fr,max-content] justify-items-center">
        <div className="mt-20 flex w-full flex-col items-center justify-center p-5 text-center tracking-wider sm:w-5/6 md:w-4/5 xl:w-3/5">
          <h1 className="text-3xl font-semibold uppercase sm:text-4xl md:text-5xl lg:text-[3.75rem]">
            Partnerships
          </h1>
          <p className="mt-10 max-w-prose md:text-lg lg:text-xl">
            Join our Global Expert Network in AI-powered Precision Immunology
          </p>
        </div>
        <ArrowInCircle className="mb-10 mt-6 h-10 animate-bounce self-end fill-tertiary [animation-duration:2500ms] sm:h-12" />
      </div>
      <div className="mt-10"></div>
      <p className="max-w-prose px-5 text-center [text-shadow:0_0_30px_black,0_0_15px_black] sm:text-lg">
        At Scienta Lab, we are fostering a culture of collaboration, at the very
        heart of our mission. Join us in creating the largest network of experts
        in immuno-inflammation.
      </p>
      <div className="mt-14"></div>
      <section className="flex flex-col flex-wrap items-center justify-center gap-2 px-5 sm:flex-row">
        <Link to="/patients">
          <button className="inline-block rounded-md border-2 border-primary bg-primary/60 px-3 py-2 font-normal text-white transition-colors hover:bg-primary/50">
            Patient / Association
          </button>
        </Link>
        <Link to="/researchers">
          <button className="inline-block rounded-md border-2 border-tertiary bg-tertiary/60 px-3 py-2 font-normal text-white transition-colors hover:bg-tertiary/50">
            Healthcare provider / Researcher
          </button>
        </Link>
        <Link to="/companies">
          <button className="inline-block rounded-md border-2 border-secondary bg-secondary/60 px-3 py-2 font-normal text-white transition-colors hover:bg-secondary/50">
            Life science company
          </button>
        </Link>
      </section>
      <div className="mb-24"></div>
    </div>
    <Footer />
  </div>
);

export default Partners;
