import quote from "../icons/quote.webp";

export type Quote = {
  photoUrl: string;
  content: string;
  name: string;
  position: string;
  company: string;
};

const PartnerQuote = ({
  photoUrl,
  content,
  name,
  position,
  company,
}: Quote) => {
  return (
    <div className="flex max-w-xl flex-col px-6 lg:max-w-4xl lg:flex-row lg:items-start lg:gap-8 lg:pl-0 xl:gap-10">
      <img
        src={photoUrl}
        className="xs:w-32 mb-4 w-24 rounded-full md:w-32 lg:w-52 xl:w-72"
        alt={`Portrait of ${name}`}
        width="320px"
        height="320px"
      />
      <div className="relative font-bold">
        <img
          className="xl-16 absolute -top-2 -left-9 w-8 brightness-[0.88] filter lg:-left-20 lg:w-12 xl:-left-24 xl:w-16"
          src={quote}
          alt=""
        />
        <p className="mb-2 text-white lg:text-lg xl:text-2xl">{content}</p>
        <div className="text-sm text-tertiary lg:text-base xl:text-xl [&_p]:leading-snug">
          <p>{name}</p>
          <p>{position}</p>
          <p>{company}</p>
        </div>
      </div>
    </div>
  );
};

export default PartnerQuote;
