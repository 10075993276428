import { CSSProperties } from "react";

import {
  Corner,
  getRoundingTailwindClass,
  sectionLeftPadding,
  sectionRightPadding,
} from "../utils";

type Side = "left" | "right";

// Do not create dynamically:
// https://tailwindcss.com/docs/content-configuration#dynamic-class-names
// Based on sectionVerticalPadding in src/utils.ts
const topPadding = "pt-12 sm:pt-20";
const bottomPadding = "pb-12 sm:pb-0";
// px-5 are based on sectionLeftPadding and sectionRightPadding in src/utils.ts
const leftColHorizontalPaddings = `px-5 sm:pr-12 lg:pr-20 ${sectionLeftPadding}`;
const rightColHorizontalPaddings = `px-5 sm:pl-12 lg:pl-20 ${sectionRightPadding}`;
export const cancelLeftColRightPaddingsStartingAtSm = "sm:-mr-12 lg:-mr-20";
export const cancelRightColLeftPaddingsStartingAtSm = "sm:-ml-12 lg:-ml-20";

// Creating local CSS variables everywhere because
// tailwind does not handle dynamic class names
// https://tailwindcss.com/docs/content-configuration#dynamic-class-names
const DoubleColsHalfFilledSection = ({
  backgroundImgUrl,
  leftChildren,
  rightChildren,
  filled,
  roundedCorners,
  commonClassesLeft = "",
  commonClassesRight = "",
  staggered = false,
}: {
  backgroundImgUrl?: string;
  leftChildren: React.ReactNode[];
  rightChildren: React.ReactNode[];
  filled: Side;
  roundedCorners?: Corner | Corner[];
  commonClassesLeft?: string;
  commonClassesRight?: string;
  staggered?: boolean;
} & React.ComponentProps<"div">) => {
  const roundingClasses = getRoundingTailwindClass(roundedCorners);
  const nbOfRowsInGrid = Math.max(leftChildren.length, rightChildren.length);
  const nbOfChildren = leftChildren.length + rightChildren.length;
  return (
    <section
      className={`grid grid-rows-[repeat(var(--rows),min-content)] sm:grid-cols-2 sm:grid-rows-[min-content,repeat(var(--rows),min-content),160px]`}
      style={{ "--rows": nbOfRowsInGrid } as CSSProperties}
    >
      {leftChildren.map((child, idx, arr) => (
        <div
          key={idx}
          className={`relative z-20 col-start-1 row-start-[var(--child-position-mobile)] sm:row-start-[var(--child-position)] ${commonClassesLeft} ${leftColHorizontalPaddings} ${getPaddings(
            idx,
            arr.length,
            staggered,
            "left"
          )}`}
          style={
            {
              "--child-position": idx + 1,
              "--child-position-mobile": staggered ? idx * 2 + 1 : idx + 1,
            } as CSSProperties
          }
        >
          {child}
        </div>
      ))}
      {rightChildren.map((child, idx, arr) => (
        <div
          key={idx}
          className={`relative z-20 col-start-1 row-start-[var(--child-position-mobile)] sm:col-start-2 sm:row-start-[var(--child-position)] ${commonClassesRight} ${rightColHorizontalPaddings} ${getPaddings(
            idx,
            arr.length,
            staggered,
            "right"
          )}`}
          style={
            {
              "--child-position": idx + 1,
              "--child-position-mobile": staggered
                ? idx * 2 + 2
                : nbOfRowsInGrid + idx + 1,
            } as CSSProperties
          }
        >
          {child}
        </div>
      ))}
      <BackgroundDiv
        {...{
          backgroundImgUrl,
          filled,
          roundingClasses,
          nbOfChildren,
          nbOfRowsInGrid,
          staggered,
        }}
      />
    </section>
  );
};

const BackgroundDiv = ({
  backgroundImgUrl,
  filled,
  roundingClasses,
  nbOfChildren,
  nbOfRowsInGrid,
  staggered,
}: {
  backgroundImgUrl?: string;
  filled: Side;
  roundingClasses: string;
  nbOfChildren: number;
  nbOfRowsInGrid: number;
  staggered: boolean;
}) => {
  const positionClasses =
    filled === "left"
      ? "col-start-1 [grid-row:1/var(--left-row-end-mobile)] sm:row-span-full"
      : `col-start-1 [grid-row:var(--right-row-start-mobile)/var(--right-row-end-mobile)] sm:col-start-2 sm:row-start-1 sm:row-span-full`;
  return (
    <div
      className={`relative z-10 overflow-hidden ${roundingClasses} ${positionClasses} ${
        backgroundImgUrl === undefined ? "bg-primary" : ""
      }`}
      style={
        {
          "--left-row-end-mobile": staggered
            ? nbOfChildren + 1
            : nbOfRowsInGrid + 1,
          "--right-row-start-mobile": nbOfRowsInGrid + 1,
          "--right-row-end-mobile": nbOfRowsInGrid * 2 + 1,
        } as CSSProperties
      }
    >
      {backgroundImgUrl !== undefined && (
        <img
          src={backgroundImgUrl}
          className="h-full w-full object-cover"
          alt=""
        />
      )}
    </div>
  );
};

// Applies (or not) padding based on the element index and
// the DoubleColsHalfFilledSection variation.
const getPaddings = (
  elementIdx: number,
  nbElements: number,
  staggered: boolean,
  side: Side
) => {
  let paddings = "";
  if (staggered) {
    if (elementIdx === 0 && side === "left") paddings += topPadding;
    if (elementIdx + 1 === nbElements && side === "right")
      paddings += ` ${bottomPadding}`;
    return paddings;
  }
  if (elementIdx === 0) paddings += topPadding;
  if (elementIdx + 1 === nbElements) paddings += ` ${bottomPadding}`;
  return paddings;
};

export default DoubleColsHalfFilledSection;
