const Logo = ({
  fillAffectsGradients = false,
  ...props
}: { fillAffectsGradients?: boolean } & React.ComponentProps<"svg">) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="30 25 327 63"
    {...props}
  >
    <title>Scienta Lab logo</title>
    <defs>
      <linearGradient
        id="lg2"
        x1="29.88"
        y1="49.8"
        x2="89.85"
        y2="49.8"
        gradientTransform="translate(1.99 6.95)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#3fffcc" />
        <stop offset=".5" stopColor="#00d2fb" />
        <stop offset=".65" stopColor="#00c8fb" />
        <stop offset=".91" stopColor="#00affb" />
        <stop offset="1" stopColor="#00a4fb" />
      </linearGradient>
      <linearGradient
        id="lg1"
        x1="309.99"
        y1="71.79"
        x2="356.6"
        y2="71.79"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#00d2fb" />
        <stop offset=".3" stopColor="#00c8fb" />
        <stop offset=".81" stopColor="#00affb" />
        <stop offset="1" stopColor="#00a4fb" />
      </linearGradient>
    </defs>
    <path
      className="cls-2"
      fill={fillAffectsGradients ? "inherit" : `url(#lg2)`}
      d="M56.8,57.47c-.38,1.41-1.49,2.52-2.9,2.9l-6.86,1.82c-.34,.09-.69,.14-1.04,.14-1.07,0-2.12-.42-2.9-1.2l-10.02-10.02c-1.03-1.03-1.44-2.54-1.06-3.95l3.67-13.69c.38-1.4,1.48-2.51,2.88-2.89l5.41-1.46c.74-.2,1.52,.24,1.72,.99,.2,.75-.24,1.52-.99,1.72l-5.41,1.46c-.44,.12-.79,.47-.91,.91l-3.67,13.69c-.12,.44,0,.92,.33,1.24l10.02,10.02c.32,.32,.8,.45,1.24,.33l6.86-1.82c.44-.12,.79-.47,.91-.91l1.44-5.38c.2-.75,.97-1.19,1.72-.99,.75,.2,1.19,.97,.99,1.72l-1.44,5.38Zm9.4,22.81l14.34-3.84c.75-.2,1.19-.97,.99-1.72-.2-.75-.97-1.19-1.72-.99l-15.9,4.26-3.82-3.82,14.34-3.84c.75-.2,1.19-.97,.99-1.72-.2-.75-.97-1.19-1.72-.99l-14.34,3.84,7.92-29.53c.38-1.41-.03-2.92-1.06-3.95l-11.12-11.12c-.55-.55-1.43-.55-1.98,0s-.55,1.43,0,1.98l4.4,4.4-14.36,3.85c-.75,.2-1.19,.97-.99,1.72,.17,.63,.73,1.04,1.35,1.04,.12,0,.24-.02,.36-.05l15.92-4.27,3.82,3.82-14.36,3.85c-.75,.2-1.19,.97-.99,1.72,.17,.63,.73,1.04,1.35,1.04,.12,0,.24-.02,.36-.05l14.35-3.84-7.92,29.53c-.38,1.41,.03,2.92,1.06,3.95l11.12,11.12c.27,.27,.63,.41,.99,.41s.72-.14,.99-.41c.55-.55,.55-1.43,0-1.98l-4.39-4.39Zm24.45-17.86l-10.02-10.02c-1.03-1.03-2.54-1.43-3.95-1.06l-6.74,1.81c-1.41,.38-2.52,1.49-2.9,2.91l-1.43,5.4c-.2,.75,.25,1.51,1,1.71,.75,.2,1.51-.25,1.71-1l1.43-5.4c.12-.45,.47-.8,.91-.91l6.74-1.81c.44-.12,.92,0,1.24,.33l10.02,10.02c.32,.32,.45,.8,.33,1.24l-3.67,13.69c-.12,.44-.47,.79-.91,.91l-5.38,1.44c-.75,.2-1.19,.97-.99,1.72,.17,.63,.73,1.04,1.35,1.04,.12,0,.24-.02,.36-.05l5.38-1.44c1.41-.38,2.52-1.48,2.89-2.89l3.67-13.69c.38-1.41-.03-2.92-1.06-3.95Z"
    />
    <g>
      <path d="M117.01,54.15c-1.96-.2-3.48-.55-4.5-1.05-1-.48-1.68-1.05-2.04-1.69-.37-.65-.55-1.34-.55-2.04,0-1.39,.57-2.39,1.75-3.06,1.18-.68,2.67-1.02,4.43-1.02,1.07,0,2.08,.13,2.88,.42,.75,.28,1.42,.67,1.97,1.15,.56,.49,1.04,1.09,1.43,1.8,.33,.57,.83,.97,1.49,1.18,.68,.22,1.32,.14,1.91-.24,.53-.34,.84-.83,.92-1.45,.08-.59-.09-1.21-.5-1.85-.66-1.08-1.45-2.04-2.36-2.84-.91-.8-1.98-1.44-3.2-1.89-1.21-.45-2.64-.68-4.26-.68-2.05,0-3.94,.32-5.61,.95-1.7,.64-3.06,1.59-4.05,2.83-1.01,1.25-1.52,2.79-1.52,4.57,0,2.43,.97,4.49,2.88,6.13,1.88,1.62,4.49,2.6,7.76,2.92,2.35,.25,4.06,.89,5.06,1.9,1.01,1.02,1.5,2.14,1.5,3.42,0,1-.29,1.81-.87,2.48-.6,.69-1.41,1.22-2.39,1.58-1.89,.7-4.31,.75-6.35,.07-.96-.32-1.79-.74-2.47-1.26-.66-.5-1.14-1.06-1.42-1.64-.27-.56-.67-.99-1.19-1.27-.53-.29-1.08-.37-1.63-.25-.75,.15-1.3,.51-1.62,1.07-.33,.57-.34,1.21-.04,1.85,.54,1.26,1.39,2.39,2.54,3.36,1.14,.96,2.5,1.71,4.03,2.24,1.53,.53,3.16,.8,4.85,.8,1.42,0,2.81-.19,4.15-.56,1.34-.37,2.55-.94,3.6-1.7,1.06-.76,1.92-1.72,2.55-2.85,.64-1.14,.96-2.49,.96-4.02,0-2.65-.91-4.83-2.7-6.45-1.77-1.61-4.26-2.59-7.4-2.9Z" />
      <path d="M142.36,48.63c.98-1.04,2.13-1.88,3.43-2.47,1.29-.59,2.68-.9,4.14-.9,1.62,0,2.93,.21,3.9,.63,.98,.42,1.94,1.04,2.88,1.85,.54,.42,1.1,.6,1.69,.56,.58-.05,1.07-.26,1.48-.64,.43-.39,.64-.9,.64-1.5,0-.26-.02-.49-.07-.68-.05-.21-.15-.43-.29-.63-.14-.2-.33-.39-.55-.56-1.47-1.21-2.96-2.09-4.43-2.62-1.47-.53-3.24-.8-5.26-.8-2.16,0-4.19,.42-6.04,1.23-1.84,.82-3.48,1.96-4.86,3.39-1.38,1.44-2.48,3.13-3.25,5.04-.78,1.91-1.17,4-1.17,6.2s.39,4.34,1.17,6.26c.78,1.92,1.87,3.62,3.25,5.06,1.38,1.44,3.03,2.57,4.88,3.38,1.86,.8,3.88,1.21,6.02,1.21,1.79,0,3.52-.29,5.14-.87,1.62-.58,3.16-1.45,4.61-2.61,.24-.24,.42-.51,.54-.8,.12-.29,.18-.59,.18-.9,0-.66-.19-1.2-.57-1.6-.37-.39-.84-.61-1.38-.66-.54-.05-1.09,.13-1.63,.52-1.05,.78-2.16,1.39-3.3,1.83-2.43,.93-5.35,.86-7.75-.22-1.29-.58-2.44-1.41-3.42-2.45-.98-1.05-1.76-2.28-2.31-3.66-.55-1.38-.83-2.89-.83-4.49s.28-3.07,.83-4.45c.55-1.38,1.32-2.61,2.31-3.66Z" />
      <path d="M171.97,41.25c-.67,0-1.24,.22-1.69,.67-.46,.45-.69,1.03-.69,1.72v26.22c0,.67,.23,1.24,.69,1.7,.46,.46,1.03,.69,1.7,.69s1.28-.23,1.72-.7c.44-.46,.67-1.03,.67-1.69v-26.22c0-.69-.23-1.27-.67-1.72-.45-.45-1.02-.67-1.72-.67Z" />
      <path d="M204.73,45.72c.69,0,1.26-.2,1.69-.6,.44-.41,.66-.94,.66-1.59s-.22-1.22-.66-1.65c-.43-.42-1-.63-1.69-.63h-17.5c-.67,0-1.24,.22-1.69,.67-.46,.45-.69,1.03-.69,1.72v26.22c0,.67,.23,1.24,.69,1.7,.46,.46,1.03,.69,1.7,.69h17.5c.69,0,1.26-.21,1.69-.63,.44-.42,.66-.97,.66-1.64s-.22-1.18-.66-1.59c-.43-.4-1-.6-1.69-.6h-15.12v-8.93h9.75c.69,0,1.26-.21,1.69-.63,.44-.42,.66-.96,.66-1.61s-.22-1.19-.66-1.61c-.43-.42-1-.63-1.69-.63h-9.75v-8.66h15.12Z" />
      <path d="M238.23,41.25c-.67,0-1.24,.22-1.69,.67-.46,.45-.69,1.03-.69,1.72v20.06l-16.22-21.58c-.49-.58-1.1-.87-1.84-.87-.67,0-1.23,.23-1.68,.67-.45,.45-.67,1.02-.67,1.72v26.22c0,.67,.22,1.24,.67,1.69,.45,.46,1.01,.7,1.68,.7s1.28-.23,1.72-.7c.44-.46,.67-1.03,.67-1.69v-20.02l16.21,21.57c.22,.29,.52,.5,.88,.64,.34,.13,.67,.2,.97,.2,.7,0,1.27-.24,1.71-.7,.43-.46,.64-1.02,.64-1.69v-26.22c0-.69-.22-1.27-.65-1.71-.43-.45-1.01-.68-1.7-.68Z" />
      <path d="M270.72,41.25h-20.72c-.64,0-1.18,.21-1.6,.63-.42,.42-.63,.96-.63,1.6s.21,1.18,.63,1.6c.42,.42,.96,.63,1.6,.63h7.99v24.14c0,.67,.23,1.24,.69,1.7,.46,.46,1.03,.69,1.7,.69s1.24-.23,1.7-.69c.46-.46,.69-1.03,.69-1.7v-24.14h7.95c.64,0,1.18-.21,1.6-.63,.42-.42,.63-.96,.63-1.6s-.21-1.18-.63-1.6c-.42-.42-.96-.63-1.6-.63Z" />
      <path d="M300.39,68.91l-10.92-26.06c-.39-1.05-1.17-1.6-2.25-1.6-1.02,0-1.8,.55-2.24,1.59l-10.88,25.98c-.36,.81-.31,1.6,.14,2.33,.46,.73,1.13,1.11,1.98,1.11,.43,0,.85-.12,1.26-.36,.42-.25,.73-.62,.95-1.11l2.12-5.19h7c.7,0,1.34-.35,1.72-.94,.38-.59,.42-1.32,.13-1.96-.33-.72-1.06-1.18-1.85-1.18h-5.33l5.07-12.4,8.72,21.66c.18,.46,.49,.83,.9,1.09,.41,.26,.85,.39,1.3,.39,.88,0,1.55-.35,2-1.04,.44-.69,.5-1.46,.17-2.3Z" />
    </g>
    <path
      className="cls-1"
      fill={fillAffectsGradients ? "inherit" : `url(#lg1)`}
      d="M322.73,79.18c.27,.27,.41,.61,.41,1.01s-.14,.74-.41,1.01c-.27,.27-.61,.41-1.01,.41h-10.33c-.4,0-.74-.14-1-.41-.26-.27-.4-.61-.4-1.01v-16.89c0-.4,.14-.74,.41-1.01,.27-.27,.61-.41,.99-.41s.74,.14,1.01,.41c.27,.27,.41,.61,.41,1.01v15.47h8.91c.4,0,.74,.14,1.01,.41Zm15.4-8.11c.62,1.06,.93,2.28,.93,3.61v5.56c0,.4-.13,.73-.4,1-.27,.26-.6,.4-1,.4s-.73-.13-1-.4c-.26-.26-.4-.6-.4-1v-.61c-.41,.46-.86,.84-1.36,1.15-.98,.62-2.1,.93-3.33,.93s-2.36-.31-3.33-.93c-.98-.62-1.76-1.46-2.33-2.52-.56-1.05-.85-2.26-.85-3.59s.31-2.54,.93-3.61c.61-1.06,1.46-1.91,2.51-2.53,1.05-.62,2.25-.93,3.57-.93s2.51,.31,3.56,.93c1.05,.62,1.89,1.47,2.5,2.53Zm-1.77,3.61c0-.87-.19-1.66-.56-2.35-.37-.68-.88-1.23-1.53-1.62-.64-.39-1.39-.59-2.21-.59s-1.54,.2-2.19,.59-1.17,.94-1.55,1.62c-.38,.68-.57,1.47-.57,2.35s.19,1.64,.57,2.32c.38,.68,.9,1.23,1.55,1.62s1.39,.59,2.19,.59,1.56-.2,2.21-.59c.64-.39,1.16-.94,1.53-1.62,.37-.69,.56-1.47,.56-2.32Zm20.24-.02c0,1.33-.31,2.54-.93,3.61-.62,1.06-1.46,1.91-2.5,2.53s-2.25,.93-3.58,.93-2.51-.31-3.56-.93c-1.05-.62-1.88-1.47-2.49-2.53-.61-1.06-.92-2.28-.94-3.6v-11.38c0-.42,.13-.76,.4-1.01,.26-.25,.6-.38,1-.38s.75,.13,1.01,.39c.26,.26,.39,.6,.39,1.01v6.4c.55-.59,1.19-1.08,1.93-1.44,.84-.41,1.77-.62,2.76-.62,1.23,0,2.36,.31,3.33,.93,.98,.62,1.76,1.46,2.33,2.52,.56,1.06,.85,2.26,.85,3.59Zm-2.7,0c0-.87-.19-1.66-.57-2.33-.38-.68-.9-1.22-1.54-1.61-.64-.39-1.38-.59-2.21-.59s-1.54,.2-2.19,.59c-.65,.39-1.17,.94-1.54,1.61-.37,.68-.56,1.46-.56,2.34s.19,1.64,.56,2.34c.37,.69,.89,1.24,1.54,1.64,.65,.39,1.39,.59,2.19,.59s1.56-.2,2.21-.59c.64-.39,1.16-.95,1.54-1.64,.38-.69,.57-1.48,.57-2.33Z"
    />
  </svg>
);

export default Logo;
