import { NewsArticle, PressRelease, Publication } from "../components/Article";
import BigSideSection from "../components/BigSideSection";
import DoubleColsHalfFilledSection from "../components/DoubleColsHalfFilledSection";
import EventCard from "../components/EventCard";
import Page from "../components/Page";
import TopSection from "../components/TopSection";
import { pictogramHeight } from "../utils";
import { pressReleases } from "./PressRelease";

import { ReactComponent as Event } from "../icons/event.svg";
import { ReactComponent as News } from "../icons/news.svg";
import { ReactComponent as Press } from "../icons/press.svg";
import { ReactComponent as PublicationPicto } from "../icons/publications.svg";
import aiForHealth from "../images/ai_for_health.webp";
import rhumato from "../images/rhumato.webp";
import background from "../images/top-section-bg.webp";

const Newsroom = () => (
  <Page>
    <div className="-mt-20"></div>
    <TopSection
      title="Newsroom"
      content="Curious about our latest updates? This is the right place."
    />
    <DoubleColsHalfFilledSection
      filled="right"
      roundedCorners="bl"
      leftChildren={inTheNewsSectionLeft}
      rightChildren={inTheNewsSectionRight}
    />
    <div className="sm:-mb-40"></div>
    <BigSideSection backgroundImgUrl={background}>
      <PressReleasesSection />
      <div className="sm:mb-28"></div>
    </BigSideSection>
    <div className="sm:-mb-20"></div>
    <DoubleColsHalfFilledSection
      filled="left"
      roundedCorners={["tr", "br"]}
      leftChildren={publicationSectionLeft}
      rightChildren={publicationSectionRight}
      commonClassesLeft="text-white"
    ></DoubleColsHalfFilledSection>
    <div className="sm:-mb-20"></div>
    <BigSideSection backgroundImgUrl={background} roundedCorners="tr">
      <EventsSection />
    </BigSideSection>
  </Page>
);

const inTheNewsSectionLeft = [
  <>
    <News
      className={`col-span-full row-start-1 mb-5 fill-secondary ${pictogramHeight}`}
    />
    <div className="mb-8 flex items-center gap-2">
      <h2 className="text-2xl font-bold text-primary">In the news</h2>
    </div>
  </>,
  <>
    <div className="space-y-8">    
    <NewsArticle
        color="secondary"
        tag="FR"
        tagTextColor="text-white"
        date="11/10/2023 "
        title="Challenge AI for Health : Qui va relever le défi de l’Institut Pasteur ?"
        content="Le 26 septembre 2023, les lauréats du Challenge AI 4 Health ont été annoncés à l’occasion du Medicen Day. C’est le consortium composé de la société Scienta Lab et du laboratoire Mathématiques et Informatique pour la Complexité des Systèmes (MICS) à CentraleSupélec qui est sélectionné pour relever le défi proposé par l’Institut Pasteur. "
        articleUrl="https://www.pasteur.fr/fr/innovation/toute-actualite/actualites-innovation/challenge-ai-health-qui-va-relever-defi-institut-pasteur"
      />
      <div className="h-px bg-black"></div>
      <NewsArticle
        color="secondary"
        tag="FR"
        tagTextColor="text-white"
        date="29/09/2023 "
        title="6 entreprises franciliennes de l’IA et du quantique à la conquête du Québec"
        content="Quelques jours après la signature d’une nouvelle feuille de route pour la coopération entre la Région et le Québec, une délégation de 6 entreprises franciliennes de l'IA et du quantique s’est rendue à Montréal du 26 au 28 septembre, à l'initiative de la Région."
        articleUrl="https://www.iledefrance.fr/6-entreprises-franciliennes-de-lia-et-du-quantique-la-conquete-du-quebec"
      />
      <div className="h-px bg-black"></div>
      <NewsArticle
        color="secondary"
        tag="FR"
        tagTextColor="text-white"
        date="24/06/2023"
        title="IA et neurosciences: le palmarès 2023 des inventeurs du Point"
        content="Robot d'assistance chirurgicale, nouveaux modèles de langage, chasse aux fake news, diagnostics connectés… Les innovations majeures de la French tech."
        articleUrl="https://www.lepoint.fr/sciences-nature/ia-et-neurosciences-notre-palmares-des-inventeurs-24-06-2023-2525991_1924.php"
      />
      <div className="h-px bg-black"></div>
      <NewsArticle
        color="secondary"
        tag="EN"
        tagTextColor="text-white"
        date="19/04/2023"
        title="Sanofi Golden Ticket 2023 Competition | Winners Announcement"
        content="Sanofi is a global sponsor of BioLabs, a premier shared laboratory space for life science and biotech startups to test new ideas and innovate. Sanofi awarded three promising start-up companies a one-year free residence at one of the following BioLabs sites..."
        articleUrl="https://www.sanofi.com/en/partnering/golden-ticket-competition"
      />
      <div className="h-px bg-black"></div>
      <NewsArticle
        color="secondary"
        tag="FR"
        tagTextColor="text-white"
        date="8/03/2023"
        title="Le jour où Sista a détourné LinkedIn pour donner de la visibilité aux femmes de la tech"
        content="À l'occasion de la Journée internationale des droits des femmes, Emmanuel Macron, Frédéric Mazzella (Blablacar), Sébastien Missoffe (Google France)… ont changé d'identité sur LinkedIn le temps d'une journée dans le cadre de l'initiative #À ma place #Takemyseat menée par Sista, l'ONU Femmes France et l'agence BETC"
        articleUrl="https://wearesista.com/linkedin-femmes-tech/"
      />
      <div className="h-px bg-black"></div>
      <p className="text-xl font-bold">
        PharmStars Announces Fall 2022 Accelerator Graduates: 11 Digital Health
        Startups Complete PharmStars' Pharma-Focused Accelerator Program
      </p>
      <NewsArticle
        color="secondary"
        tag="EN"
        tagTextColor="text-white"
        date="08/12/22"
        title="PR"
        content="PharmStars, the pharma-focused accelerator for digital health startups, announces the graduation of 11 startups 
                 from its Fall 2022 program focused on “Innovations in Real-World Evidence.” The graduating startups successfully 
                 completed PharmaU, PharmStars' 10-week education, and mentoring program. PharmaU culminated with a 
                 Showcase Event--held in Boston in early November--that brought together the participating startups and PharmStars' 
                 seven innovation-minded pharma members."
        articleUrl="https://www.pr.com/press-release/852211"
      />
    </div>
    <div className="sm:mb-14" />
  </>,
];

const inTheNewsSectionRight = [
  <div></div>,
  <div className="space-y-8 text-white">
    <p className="text-xl font-bold text-white">
    Here are this year’s top 40 startups at Station F
    </p>
    <NewsArticle
      color="tertiary"
      tag="FR"
      tagTextColor="text-primary"
      date="15/11/2023"
      title="TechCrunch"
      content="Every year, hundreds of (very) early-stage startups choose to participate in one of the startup programs of Station F, an iconic startup campus based in Paris. And Station F has shared a list of the 40 most promising startups as part of the Future 40 selection."
      articleUrl="https://techcrunch.com/2023/11/15/here-are-this-years-top-40-startups-at-station-f/"
    />
    <div className="h-px bg-white"></div>
    <p className="text-xl font-bold text-white">
    Future 40 : quelles sont les nouvelles pépites mises en valeur par Station F ?
    </p>
    <NewsArticle
      color="tertiary"
      tag="FR"
      tagTextColor="text-primary"
      date="15/11/2023"
      title="Maddyness"
      content="Chaque année depuis 2019, Station F sélectionne ses startups les plus prometteuses. Celles-ci sont mises en lumière au sein de l’indice Future 40. La cinquième promotion vient d’être dévoilée."
      articleUrl="https://www.maddyness.com/2023/11/15/future-40-quelles-sont-les-nouvelles-pepites-mises-en-valeur-par-station-f/"
    />
    <div className="h-px bg-white"></div>
    <p className="text-xl font-bold text-white">
    Station F announces Top 40 Pre-seed & Seed companies for 2023
    </p>
    <NewsArticle
      color="tertiary"
      tag="EN"
      tagTextColor="text-primary"
      date="15/11/2023"
      title="Station F"
      content="Every November since 2019, Station F publishes its top 40 seed and pre-seed companies in a list known as Future 40. These companies are carefully picked from the 1,000 startups on campus each year, meaning that they represent the top 4% of startups campus for a given year."
      articleUrl="https://stationf.co/news/future40-2023"
    />
    <div className="h-px bg-white"></div>
    <p className="text-xl font-bold text-white">
      Arnaud Delubac (Greenly) & Vincent Bouget (Scienta Lab): la plateforme
      logicielle de Greenly pour la réalisation de bilan carbone des PME et
      l'exploitation du potentiel de l'intelligence artificielle de Scienta Lab.
    </p>
    <NewsArticle
      color="tertiary"
      tag="FR"
      tagTextColor="text-primary"
      date="04/07/22"
      title="BFM Business"
      content="Arnaud Delubac, cofondateur de Greenly, ainsi que Vincent Bouget,
          directeur scientifique de Scienta Lab, sont revenus sur l'activité de
          leurs sociétés respectives, dans l'émission Tech & Co présenté par
          François Sorel."
      articleUrl="https://www.bfmtv.com/economie/replay-emissions/tech-and-co/arnaud-delubac-greenly-vincent-bouget-scienta-lab-la-plateforme-logicielle-de-greenly-pour-la-realisation-de-bilan-carbone-des-pme-et-l-exploitation-du-potentiel-de-l-intelligence-artificielle-par-scienta-lab-04-07_VN-202207040690.html"
    />
    <div className="h-px bg-white"></div>
    <p className="text-xl font-bold text-white">
      Station F dévoile les 16 nouvelles entrepreneuses du Female Founders
      Fellowship.
    </p>
    <NewsArticle
      color="tertiary"
      tag="FR"
      tagTextColor="text-primary"
      date="08/03/22"
      title="Maddyness"
      content="À l'occasion de la journée internationale des droits des femmes, 
      Station F révèle les noms des fondatrices sélectionnées au sein du Female Founders Fellowship, 
      un programme annuel qui accompagnera pendant 6 mois les fondatrices de startups &#171; à haut potentiel &#187; 
      afin d'accélérer leur activité."
      articleUrl="https://www.maddyness.com/2022/03/08/station-f-entrepreneuses-female-founders-fellowship-2/"
    />
    <div className="h-px bg-white"></div>
    <p className="text-xl font-bold">
      Intelligence Artificielle: une aide au choix des traitements dans la PR.
    </p>
    <NewsArticle
      color="tertiary"
      tag="FR"
      tagTextColor="text-primary"
      date="01/03/22"
      title="ANDAR Infos"
      content="L'association Nationale de Défense contre la Polyarthrite rhumatoïde (ANDAR) aborde l'apport de l'intelligence
        artificielle dans la prise en charge de la PR et revient sur les travaux préliminaires de Scienta Lab."
      articleUrl={`${process.env.PUBLIC_URL}/static/andar.pdf`}
    />
  </div>,
];

// publicationSectionLeft and publicationSectionRight are created here line by line,
// meaning alternating a publication and a divider. Did this so that publications are
// perfectly aligned. Indeed, as the publications height were very close (but not exactly the same),
// it seemed that the fact that they were not aligned was unintended.
// If you want to add a publication with a different height or if publications length change, you can remove
// this line by line pattern and put every publication in the same line (as it is done in inTheNewsSection or
// stationFSection for example, using a wrapping div with space-y-8).
const publicationSectionLeft = [
  <>
    <PublicationPicto
      className={`col-span-full row-start-1 mb-5 fill-tertiary ${pictogramHeight}`}
    />
    <h2 className="mr-2 whitespace-pre-line text-2xl font-bold text-white">
      Publications and
      <br />
      abstracts
    </h2>
    <div className="mb-8" />
  </>,
  <Publication
    color="tertiary"
    tag="abstract"
    tagTextColor="text-white"
    date="30/05/2023"
    title="EULAR Congress"
    content="Deep learning accurately predicts focus score and diagnosis of primary sjögren syndrome using labial salivary gland biopsie"
    authors="L.Basseto, J.Duquesne, V.Bouget, M.Barnes, E.Pontarini, A.Gallagher-Syed, M.Bombardieri, B.Fisher, S.Nayar, C.Adam, T.Lazure, X.Mariette, S.Bitoun"
    authorsTextColors="text-tertiary"
    articleUrl="https://ard.bmj.com/content/82/Suppl_1/152.2"
  />,
  <div className="my-8 h-px bg-white" />,
  <Publication
    color="tertiary"
    tag="publication"
    tagTextColor="text-white"
    date="23/11/22"
    title="Rheumatology"
    content="Machine learning identifies a profile of inadequate responder to methotrexate in rheumatoid arthritis"
    authors="J.Duquesne, V.Bouget, P-H.Cournède, B.Fautrel, F.Guillemin, P.de Jong, J.Heutz, M.Verstappen, A.Van der Helm-van Mil, X.Mariette, S.Bitoun"
    authorsTextColors="text-tertiary"
    articleUrl="https://academic.oup.com/rheumatology/advance-article/doi/10.1093/rheumatology/keac645/6842332"
  />,
  <div className="my-8 h-px bg-white" />,
  <Publication
    color="tertiary"
    tag="abstract"
    tagTextColor="text-white"
    date="13/11/22"
    title="ACR Congress"
    content="Machine Learning Identifies Biomarker of Non-Response to Methotrexate in Rheumatoid Arthritis"
    authors="V.Bouget, J.Duquesne, P-H.Cournède, B.Fautrel, F.Guillemin, P.De Jong, J.Heutz, A.Van der Helm-van Mil, M.Verstappen, X.Mariette, S.Bitoun"
    authorsTextColors="text-tertiary"
    articleUrl="https://acrabstracts.org/abstract/machine-learning-identifies-biomarker-of-non-response-to-methotrexate-in-rheumatoid-arthritis/"
  />,
  <div className="my-8 h-px bg-white" />,
  <Publication
    color="tertiary"
    tag="publication"
    tagTextColor="text-white"
    date="23/08/22"
    title="RMD Open"
    content="Machine learning predicts response to TNF inhibitors in Rheumatoid Arthritis: results on the ESPOIR and ABIRISK cohorts."
    authors="V.Bouget, J.Duquesne, S.Hassler, P-H.Cournède, B.Fautrel, F.Guillemin, M.Pallardy, P.Broët, X.Mariette, S.Bitoun"
    authorsTextColors="text-tertiary"
    articleUrl="https://rmdopen.bmj.com/content/8/2/e002442"
  />,
];

// Check comment above publicationSectionLeft
const publicationSectionRight = [
  <></>,
  <Publication
    color="secondary"
    tag="abstract"
    tagTextColor="text-white"
    date="23/05/22"
    title="EULAR Congress"
    content="Machine learning predicts response to methotrexate in rheumatoid arthritis: results on the ESPOIR, t-Reach and Leiden cohorts."
    authors="V.Bouget, J.Duquesne, P-H.Cournède, B.Fautrel, F.Guillemin, P.De Jong, J.Heutz, A.Van der Helm-van Mil, M.Verstappen, S.Bitoun, X.Mariette"
    authorsTextColors="text-secondary"
    articleUrl="https://ard.bmj.com/content/81/Suppl_1/535.1"
  />,
  <div className="my-8 h-px bg-black" />,
  <Publication
    color="secondary"
    tag="abstract"
    tagTextColor="text-white"
    date="23/05/22"
    title="EULAR Congress"
    content="Machine learning predicts response to TNF inhibitors in rheumatoid arthritis: results on the ESPOIR, t-Reach and Leiden cohorts."
    authors="J.Duquesne, V.Bouget, S.Hassler, P-H.Cournède, B.Fautrel, F.Guillemin, M.Pallardy, P.Broët, X.Mariette, S.Bitoun"
    authorsTextColors="text-secondary"
    articleUrl="https://ard.bmj.com/content/81/Suppl_1/534"
  />,
  <div className="my-8 h-px bg-black" />,
  <Publication
    color="secondary"
    tag="abstract"
    tagTextColor="text-white"
    date="01/12/2021"
    title="SFR Congress"
    content="Prédiction de la non-réponse au méthotrexate dans la polyarthrite rhumatoïde par l'intelligence artificielle : résultats des cohortes ESPOIR, Leiden et t-REACH"
    authors="V.Bouget, J.Duquesne, P-H.Cournède, B.Fautrel, F.Guillemin, P.De Jong, J.Heutz, A.Van der Helm-van Mil, M.Verstappen, S.Bitoun, X.Mariette"
    authorsTextColors="text-secondary"
    articleUrl="https://www.sciencedirect.com/science/article/abs/pii/S1169833021002945"
  />,
];

const PressReleasesSection = () => (
  <>
    <Press
      className={`col-span-full row-start-1 mb-5 fill-tertiary ${pictogramHeight}`}
    />
    <div className="space-y-8">
      <h2 className="text-2xl font-bold text-white">Press releases</h2>
      {pressReleases.map((pressRelease) => (
        <PressRelease key={pressRelease.slug} {...pressRelease} />
      ))}
    </div>
  </>
);

const EventsSection = () => (
  <>
    <div className="sm:mt-10"></div>
    <Event
      className={`col-span-full row-start-1 mb-5 fill-tertiary ${pictogramHeight}`}
    />
    <div className="space-y-8">
      <h2 className="text-2xl font-bold text-white">Events</h2>
      <p>Meet us there:</p>
      <EventCard
        eventName="36ème Congrès Français de Rhumatologie"
        date="10-12 Dec 2023"
        location="Paris, France"
        registrationUrl="https://sfr.larhumatologie.fr/actualites/inscriptions-au-36e-congres-rhumatologie"
        eventImgUrl={rhumato}
        color="tertiary"
        tagTextColor="text-[#0e1f21]"
      />
      <div className="h-px bg-white"></div>
      <div className="[&_img]:-ml-5 [&_img]:[filter:drop-shadow(0px_0px_6px_black)]">
        <EventCard
          eventName="IA for Health"
          date="22 Nov 2023"
          location="Paris, Station F"
          registrationUrl="https://www.aiforhealth.fr/"
          eventImgUrl={aiForHealth}
          color="tertiary"
          tagTextColor="text-[#0e1f21]"
        />
      </div>
    </div>
  </>
);

export default Newsroom;
