import { Link } from "react-router-dom";

import { BtnOutlinePlus } from "./Buttons";
import Tag from "./Tag";
import {
  WithRequiredProperties,
  tailwindTextColorClass,
  themeColor,
} from "../utils";

const Article = ({
  color,
  tag,
  tagTextColor,
  date,
  title,
  content,
  authors,
  authorsTextColors,
  articleUrl,
  slug,
}: ArticleData) => {
  const isArticleAPressRelease = slug !== undefined;
  return (
    <article>
      <div className="flex flex-row space-x-2">
        <Tag content={date} color={color} tagTextColor={tagTextColor} />
        {tag !== undefined && (
          <Tag content={tag} color={color} tagTextColor={tagTextColor} />
        )}
      </div>
      <h3
        className={`mt-1 font-bold text-${color} ${
          isArticleAPressRelease && "mb-3"
        }`}
      >
        {title}
      </h3>
      {!isArticleAPressRelease && <div className="mt-2 mb-3">{content}</div>}
      {authors !== undefined && (
        <p className={`mb-3 font-bold ${authorsTextColors}`}>{authors}</p>
      )}
      {slug !== undefined ? (
        <Link to={`/press-release/${slug}`}>
          <BtnOutlinePlus color={color} />
        </Link>
      ) : (
        articleUrl !== undefined && (
          <a target="_blank" rel="noreferrer" href={articleUrl}>
            <BtnOutlinePlus color={color} />
          </a>
        )
      )}
    </article>
  );
};

export const NewsArticle = (props: NewsArticleData) => <Article {...props} />;

export const Publication = (props: PublicationData) => <Article {...props} />;

export const PressRelease = (props: PressReleaseData) => <Article {...props} />;

type ArticleData = {
  color: themeColor;
  tag?: "FR" | "EN" | "publication" | "abstract";
  // Text cutting through background is complicated and overly complex for a simple tag.
  // Downgrading to the ability to pass an arbitrary color
  // https://stackoverflow.com/questions/13932946/transparent-text-cut-out-of-background
  tagTextColor: tailwindTextColorClass;
  date: string;
  title: string;
  content: React.ReactNode;
  authors?: string;
  authorsTextColors?: tailwindTextColorClass;
  articleUrl?: string;
  slug?: string;
};
type NewsArticleData = WithRequiredProperties<
  Omit<ArticleData, "authors" | "authorsTextColors" | "slug">,
  "articleUrl"
>;
type PublicationData = WithRequiredProperties<
  Omit<ArticleData, "slug">,
  "articleUrl" | "authors" | "authorsTextColors"
>;
export type PressReleaseData = WithRequiredProperties<
  Omit<ArticleData, "authors" | "authorsTextColors" | "articleUrl">,
  "slug"
>;
