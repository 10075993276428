import Page from "../components/Page";
import TopSection from "../components/TopSection";
import { pictogramHeight, sectionPadding } from "../utils";

import { ReactComponent as Policy } from "../icons/policy.svg";

const PrivacyPolicy = () => (
  <Page>
    <div className="-mt-20"></div>
    <TopSection title="Privacy policy" />
    <div className={`mb-20 ${sectionPadding}`}>
      <Policy className={`mb-5 fill-secondary ${pictogramHeight}`} />
      <h2 className="text-2xl font-bold text-primary">
        Customer and contacts personal data
        <br />
        protection policy
      </h2>
      <div className="mb-8" />
      <h5 className="mb-8 text-lg font-bold">Table of contents</h5>
      <ul className="mb-24 list-inside list-decimal space-y-3 [&>li]:underline [&>li]:underline-offset-2">
        <li>
          <a href="#1">Foreword</a>
        </li>
        <li>
          <a href="#2">Purpose</a>
        </li>
        <li>
          <a href="#3">Scope</a>
        </li>
        <li>
          <a href="#4">General principles &amp; data collection</a>
        </li>
        <li>
          <a href="#5">Types of data collected</a>
        </li>
        <li>
          <a href="#6">Data sources</a>
        </li>
        <li>
          <a href="#7">Purposes and legal bases</a>
        </li>
        <li>
          <a href="#8">Data recipient – authorisation &amp; traceability</a>
        </li>
        <li>
          <a href="#9">Retention period</a>
        </li>
        <li>
          <a href="#10">Confirmation and access right</a>
        </li>
        <li>
          <a href="#11">Updating and rectification</a>
        </li>
        <li>
          <a href="#12">Right to deletion</a>
        </li>
        <li>
          <a href="#13">Right to restrict processing</a>
        </li>
        <li>
          <a href="#14">Data portability right</a>
        </li>
        <li>
          <a href="#15">Automated individual decision making</a>
        </li>
        <li>
          <a href="#16">Rights after death</a>
        </li>
        <li>
          <a href="#17">Optional or mandatory nature of responses</a>
        </li>
        <li>
          <a href="#18">Right of use</a>
        </li>
        <li>
          <a href="#19">Data processors</a>
        </li>
        <li>
          <a href="#20">Security</a>
        </li>
        <li>
          <a href="#21">Data breach</a>
        </li>
        <li>
          <a href="#22">Data protection officer</a>
        </li>
        <li>
          <a href="#23">Processing register</a>
        </li>
        <li>
          <a href="#24">Right to submit a complaint to the CNIL</a>
        </li>
        <li>
          <a href="#25">Regulatory developments</a>
        </li>
        <li>
          <a href="#26">For further information</a>
        </li>
      </ul>
      <div className="space-y-8">
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="1">
          1. Foreword
        </h5>
        <p>
          Regulation (EU) 2016/679 of the European Parliament and of the Council
          dated 27 April 2016 on the protection of natural persons regarding the
          processing of personal data and the free movement of such data,
          otherwise known as the General Data Protection Regulation (GDPR) sets
          out the legal framework for the processing of personal data.
        </p>
        <p>
          The GDPR strengthens the rights and obligations of data controllers,
          processors, data subjects and data recipients.
        </p>
        <p>
          We process personal data during the course of our business activities.
        </p>
        <p>
          Please note the following definitions of certain terms used in this
          policy:
        </p>
        <ul className="list-inside list-disc space-y-3">
          <li>
            Data controller: SCIENTA LAB, (hereafter the &quot;Entity&quot;);
          </li>
          <li>
            Data processor: natural person or legal entity who processes
            personal data on behalf of the Organisation;
          </li>
          <li>Data subject: customers and/or contacts of the Entity;</li>
          <li>
            Data recipient: a natural person or legal entity who receive
            personal data from the Entity. Data recipients may therefore also be
            employees of the Entity or of external entities (partners,
            exhibitors, banks, service providers, etc.).
          </li>
        </ul>
        <p>
          In Article 12, the GDPR requires that data subjects are notified of
          their rights in a concise, transparent, comprehensible and easily
          accessible manner.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="2">
          2. Purpose
        </h5>
        <p>
          The purpose of this policy is to meet the information obligation of
          the Entity under the GDPR (Article 12) and to document the rights and
          obligations of its customers and contacts regarding the processing of
          their personal data.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="3">
          3. Scope
        </h5>
        <p>
          This policy applies to all processing of the personal data of the
          Entity&#39;s customers and contacts.
        </p>
        <p>
          The Entity makes every effort to ensure that said data is processed
          within the framework of strict internal governance. That being said,
          this policy only covers data of which the Entity is the data
          controller and therefore not any processing that may be established or
          performed outside the scope of governance specified by the Entity
          (so-called &#39;shadow IT&#39;).
        </p>
        <p>
          The processing of personal data may be managed directly by the Entity
          or via a data processor specifically designated by the Entity.
        </p>
        <p>
          This policy is independent of any other document that may apply in the
          context of the contractual relationship between the Entity and its
          customers or contacts.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="4">
          4. General Principles &amp; Data Collection
        </h5>
        <p>
          The Entity does not process any data of customers or contacts if not
          relating to the personal data collected by or for our departments or
          processed in association with our departments and if it does not
          comply with the general principles of the GDPR.
        </p>
        <p>The Entity may use personal data for the following purposes:</p>
        <div className="-mx-5 overflow-x-auto border border-primary sm:-mx-8 md:mx-0 md:rounded-xl">
          <table className="w-full min-w-max divide-y divide-primary">
            <tbody className="divide-y divide-primary [&_td]:p-6 [&_tr]:divide-x [&_tr]:divide-primary [&_td>*]:max-w-prose [&_td>*]:2xl:max-w-screen-lg">
              <tr>
                <td>Push media</td>
                <td className="space-y-3">
                  <p>All sales and sales management activities.</p>
                  <p>
                    Marketing campaigns, generally via e-mail, SMS, phone, etc.
                  </p>
                  <p>Media advertising.</p>
                </td>
              </tr>
              <tr>
                <td>Events</td>
                <td className="space-y-3">
                  <p>
                    Physical events organised by the Entity, in which the Entity
                    participates or which it sponsors.
                  </p>
                  <p>
                    Data collected when registering for an event (directly or
                    via a partner) or during the event itself (form,
                    questionnaire, business card, dedicated mobile app, etc.).
                  </p>
                </td>
              </tr>
              <tr>
                <td>Social media</td>
                <td>
                  <p>
                    Any social selling campaign. This notably includes the
                    collection of data relating to registrations, posts, likes,
                    replies, forwards, comments, opinions, etc.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          Although the list is intended to be as exhaustive as possible, any new
          uses or modification or withdrawal of any existing processing will be
          notified to customers and contacts by way of an amendment of this
          policy.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="5">
          5. Types of Data Collected
        </h5>
        <div className="-mx-5 overflow-x-auto border border-primary sm:-mx-8 md:mx-0 md:rounded-xl">
          <table className="w-full min-w-max divide-y divide-primary">
            <tbody className="divide-y divide-primary [&_td]:p-6 [&_tr]:divide-x [&_tr]:divide-primary [&_td>*]:max-w-prose [&_td>*]:2xl:max-w-screen-lg">
              <tr>
                <td>
                  Non-technical data
                  <br />
                  (depending on the circumstances)
                </td>
                <td className="space-y-3">
                  <p>
                    Identity and identification (surname, first name, date of
                    birth, pseudonym, customer number)
                  </p>
                  <p>
                    Contact details (e-mail, postal address, phone number):
                    notably for sending newsletters
                  </p>
                  <p>
                    Professional activities, if applicable (company name,
                    function)
                  </p>
                  <p>Bank details, if required</p>
                  <p>Data relating to current contracts</p>
                </td>
              </tr>
              <tr>
                <td>
                  Technical data
                  <br />
                  (depending on the circumstances)
                </td>
                <td className="space-y-3">
                  <p>Identification data (IP)</p>
                  <p>Connection data (logs in particular)</p>
                  <p>Acceptance data (clicks)</p>
                  <p>Location data</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="6">
          6. Data Sources
        </h5>
        <p>
          Data relating to our customers and contacts is generally collected
          from them directly (direct collection).
        </p>
        <p>
          Collection may also be indirect via specialist companies or partners
          and suppliers of the Entity. In such cases, the Entity takes the
          greatest of care to ensure the quality of data it receives.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="7">
          7. Purposes and legal bases
        </h5>
        <p>
          The Entity processes your data for the following purposes, as
          applicable:
        </p>
        <ul className="list-inside list-disc space-y-3">
          <li>Customer relationship management (CRM);</li>
          <li>Contact relationship management (PRM);</li>
          <li>Organising events;</li>
          <li>Purchasing administration;</li>
          <li>Business monitoring;</li>
          <li>Newsletter administration;</li>
          <li>Improving services and satisfaction surveys;</li>
          <li>Targeted advertising and segmentation;</li>
          <li>Mobile app administration;</li>
          <li>Statistics.</li>
        </ul>
        <p>
          These purposes are based on the legitimate interests of the Entity to
          hold data concerning its users and contacts, and when necessary on the
          consent.
        </p>
        <p>
          The Entity&#39;s relationships with its customers will be based on the
          general terms and conditions of sale, duly accepted on registration.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="8">
          8. Data Recipients – Authorisation &amp; Traceability
        </h5>
        <p>
          The Entity ensures that data can only be accessed by authorised
          internal and external recipients.
        </p>
        <div className="-mx-5 overflow-x-auto border border-primary sm:-mx-8 md:mx-0 md:rounded-xl">
          <table className="w-full min-w-max divide-y divide-primary">
            <tbody className="divide-y divide-primary [&_td]:p-6 [&_tr]:divide-x [&_tr]:divide-primary [&_td>*]:max-w-prose [&_th]:p-6 [&_th]:text-primary">
              <tr>
                <th>Internal recipients</th>
                <th>External recipients</th>
              </tr>
              <tr>
                <td>
                  <ul className="list-inside list-disc space-y-3">
                    <li>
                      Authorised personnel from Marketing, departments
                      responsible for managing the customer relationship and
                      sales development, Communications, Sales, IT and their
                      line managers;
                    </li>
                    <li>
                      Authorised personnel from departments responsible for
                      control and audit functions (departments responsible for
                      internal control procedures, etc.);
                    </li>
                  </ul>
                </td>
                <td>
                  <ul className="list-inside list-disc space-y-3">
                    <li>
                      Partners, external companies and subsidiaries of a single
                      group of companies;
                    </li>
                    <li>
                      Organisations, officers of the court and judicial officers
                      in the context of their debt collection functions;
                    </li>
                    <li>
                      The body responsible for managing the list of cold-calling
                      prohibitions;
                    </li>
                    <li>Data processors&#39; authorised personnel.</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          Recipients within the Entity of the personal data of customers and
          contacts are bound by a confidentiality obligation.
        </p>
        <p>
          The Entity decides which recipients may access which data by means of
          an authorisation policy.
        </p>
        <p>
          All access to the processing of customers&#39; and contacts&#39;
          personal data is traceable. Personal data may also be forwarded to any
          authority legally entitled to receive it. In such cases, the Entity is
          not liable for the manner in which said authorities access and exploit
          the data.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="9">
          9. Retention Period
        </h5>
        <p>
          The retention period of applicant data is defined by the Entity in
          accordance with its legal and contractual obligations and, failing
          this, depending on the specific needs, notably in accordance with the
          following principles:
        </p>
        <div className="-mx-5 overflow-x-auto border border-primary sm:-mx-8 md:mx-0 md:rounded-xl">
          <table className="w-full min-w-max divide-y divide-primary">
            <tbody className="divide-y divide-primary [&_td]:p-6 [&_tr]:divide-x [&_tr]:divide-primary [&_td>*]:max-w-prose [&_td>*]:2xl:max-w-screen-lg [&_th]:p-6 [&_th]:text-primary">
              <tr>
                <th>Processing</th>
                <th>Retention period</th>
              </tr>
              <tr>
                <td>
                  <p>Customer data</p>
                </td>
                <td>
                  <p>
                    For the duration of contractual relations with the Entity,
                    plus 5 years for sales development purposes, without
                    prejudice to storage and retention obligations or the
                    statute of limitations
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Data relating to contacts and potential customers</p>
                </td>
                <td>
                  <p>
                    3 years from collection of the data by the Entity or from
                    the last contact made by the potential customer or contact
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Targeted advertising data</p>
                </td>
                <td>
                  <p>
                    6 months to 1 year from collection, depending on the
                    campaign
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Bank details</p>
                </td>
                <td>
                  <p>Deleted on expiry of the master agreement</p>
                  <p>
                    If a transaction is disputed: 13 months&#39; retention in
                    the files following the data of debit
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          After the specified periods, data is either deleted or retained after
          anonymisation, notably for statistical purposes. It may be retained in
          the event of pre-litigation and litigation.
        </p>
        <p>
          Customers and contacts are reminded that deletion or anonymisation are
          irreversible operations and data cannot be subsequently restored by
          the Entity.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="10">
          10. Confirmation and Acces Right
        </h5>
        <p>
          Customers and contacts are entitled to request the Entity to issue
          confirmation of whether or not their personal data is being processed.
        </p>
        <p>
          Customers and contacts also enjoy an access right, subject to
          compliance with the following rules:
        </p>
        <ul className="list-inside list-disc space-y-3">
          <li>
            The request is issued personally and is accompanied by a valid
            identity document;
          </li>
          <li>
            It is issued in writing to the following address: 3 RUE JOLIOT CURIE
            91190 GIF- SUR-YVETTE or e-mail address{" "}
            <a
              className="text-primary underline"
              href="mailto:dpo-scientalab@racine.eu"
            >
              dpo-scientalab@racine.eu
            </a>
            .
          </li>
        </ul>
        <p>
          Customers and contacts are entitled to request a copy of their
          personal data being processed by the Entity. However, in the event of
          any additional copies being requested, the Entity may require the
          customer or contact to cover the associated costs.
        </p>
        <p>
          If customers or contacts request a copy of their personal data via
          electronic means, the requested information will be provided in a
          commonly used electronic format, unless specified otherwise.
        </p>
        <p>
          Customers and contacts are notified that this access right may not
          cover confidential information or data, or data for which
          communication is prohibited by law.
        </p>
        <p>
          The access right may not be exercised in an abusive manner, i.e.
          exercised legally yet with the sole objective of undermining the
          proper execution of the service in question.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="11">
          11. Updating and Rectification
        </h5>
        <p>The Entity will meet updating requests:</p>
        <ul className="list-inside list-disc space-y-3">
          <li>
            Automatically, for online modifications relating to fields that may
            be updated technically or legally;
          </li>
          <li>
            On written request, issued by the data subject personally on proof
            of identity.
          </li>
        </ul>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="12">
          12. Right to Deletion
        </h5>
        <p>
          The deletion right of customers and contacts does not apply where
          processing is carried out in compliance with a legal obligation.
        </p>
        <p>
          In other circumstances, customers and contacts may request deletion of
          their data if any of the following criteria are met:
        </p>
        <ul className="list-inside list-disc space-y-3">
          <li>
            The personal data is no longer necessary in relation to the purposes
            for which it was collected or otherwise processed;
          </li>
          <li>
            If the data subject withdraws the consent on which the processing
            has been based and there exists no other legal basis;
          </li>
          <li>
            The data subject objects to processing required for the Entity to
            pursue its legitimate interests and there exists no other pressing
            and legitimate reason to continue processing;
          </li>
          <li>
            The data subject objects to the processing of their personal data
            for marketing purposes, including profiling;
          </li>
          <li>The personal data has been processed unlawfully.</li>
        </ul>
        <p>
          In accordance with legislation of personal data protection, customers
          and contacts are notified that this is an individual right that may
          only be exercised by the data subject in relation to their own
          information: for security reasons, the department concerned must
          therefore verify your identity before communicating any of your
          confidential information to a person other than you.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="13">
          13. Right to Restrict Processing
        </h5>
        <p>
          Customers and contacts are notified that the right to restrict
          processing is not intended to apply when the processing carried out by
          the Entity is legal and all the personal data collected is necessary
          for performance of its services.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="14">
          14. Data Portability Right
        </h5>
        <p>
          The Entity will accede to data portability requests in the specific
          circumstances of data communicated by customers and contacts
          personally, via online services provided by the Entity itself and for
          purposes based solely on personal consent. In such cases, the data
          will be communicated in structured and commonly used format able to be
          read by a machine.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="15">
          15. Automated Individual Decision-Making
        </h5>
        <p>
          The Entity does not carry out automated individual decision-making.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="16">
          16. Rights After Death
        </h5>
        <p>
          Customers and contacts are notified that they enjoy the right to issue
          instructions concerning the retention, deletion and communication of
          your data after their death. The communication of specific
          instructions for the exercise of rights after death are to be issued
          by e-mail at{" "}
          <a
            className="text-primary underline"
            href="mailto:dpo-scientalab@racine.eu"
          >
            dpo-scientalab@racine.eu
          </a>{" "}
          or by post at 3 RUE JOLIOT CURIE 91190 GIF-SUR- YVETTE, accompanied by
          a copy of a signed identity document.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="17">
          17. Optional or Mandatory Nature of Responses
        </h5>
        <p>
          Customers and contacts are notified on every personal data collection
          form of the mandatory or optional nature of responses by means of an
          asterisk.
        </p>
        <p>
          If a response is mandatory, the Entity explains the consequences of
          non-response to customers and contacts.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="18">
          18. Right of Use
        </h5>
        <p>
          The Entity is assigned by customers and contacts a right to use and
          process their personal data for the aforementioned purposes.
        </p>
        <p>
          However, any data supplemented by the processing and analysis of the
          Entity, otherwise known as supplemented data, shall remain the
          exclusive property of the Entity (usage analysis, statistics, etc.).
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="19">
          19. Data Processors
        </h5>
        <p>
          The Entity notifies its customers and contacts that it may engage any
          processor of its choice to process their personal data.
        </p>
        <p>
          In any such case, the Entity ensures that the processor complies with
          its obligations under the GDPR.
        </p>
        <p>
          The Entity undertakes to sign a contract with all processors, imposing
          on the latter the same data protection obligations that apply to the
          Entity. Furthermore, the Entity reserves the right to perform an audit
          on the processor to verify the latter&#39;s compliance with its
          obligations under the GDPR.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="20">
          20. Security
        </h5>
        <p>
          The Entity is required to implement security techniques of a physical
          or logical nature which it judges to be appropriate to prevent the
          destruction, loss, degradation or unauthorised disclosure of data in
          an accidental or illegal manner.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="21">
          21. Data Breach
        </h5>
        <p>
          In the event of any breach of personal data, the Entity undertakes to
          notify CNIL as set out in the GDPR.
        </p>
        <p>
          Should any such breach present a high level of risk for customers and
          contacts and the data has not been protected, the Entity shall:
        </p>
        <ul className="list-inside list-disc space-y-3">
          <li>Notify the customers and contacts concerned;</li>
          <li>
            Issue the necessary information and recommendations to the customers
            and contacts concerned.
          </li>
        </ul>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="22">
          22. Data Protection Officer
        </h5>
        <p>The Entity has designated a Data Protection Officer.</p>
        <p>
          The contact details of the Data Protection Officer are as follows:
        </p>
        <ul className="list-inside list-disc space-y-3">
          <li>Name : Eric Barbry;</li>
          <li>E-mail address : 40 rue de Courcelles;</li>
          <li>Tel : +33 1 44 82 43 00.</li>
        </ul>
        <p>
          If personal data is to be subjected to additional processing, the
          Entity will notify the Data Protection Officer in advance.
        </p>
        <p>
          Should customers and contacts wish to obtain any particular
          information or pose a specific question, they may contact the Data
          Protection Officer who will provide a response within a reasonable
          period in light of the question posed or information requested.
        </p>
        <p>
          In the event of encountering any problem with the processing of
          personal data, customers and contacts may contact the designated Data
          Protection Officer.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="23">
          23. Processing Register
        </h5>
        <p>
          As data controller, the Entity undertakes to maintain a register
          recording all completed processing activities.
        </p>
        <p>
          This register is a document or app that lists all processing carried
          out by the Entity in its capacity as data controller.
        </p>
        <p>
          The Entity undertakes to provide any supervisory authority on request
          with all information enabling said authority to verify the compliance
          of processing with applicable data protection regulations.
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="24">
          24. Right to Submit a Complaint to CNIL
        </h5>
        <p>
          Customers and contacts concerned by the processing of their personal
          data enjoy the right to submit a complaint to a supervisory authority,
          i.e. CNIL in France, should they believe that the processing of their
          personal data does not comply with EU data protection regulations, at
          the following address:
        </p>
        <p>
          <b>CNIL</b> - Complaints Department
        </p>
        <p>3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07 - FRANCE</p>
        <p>Tel : +33 1 53 73 22 22</p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="25">
          25. Regulatory Developments
        </h5>
        <p>
          This policy may be amended or supplemented at any time in the event of
          legal or judicial developments, or in response to new uses and any
          decisions or recommendations issued by CNIL.
        </p>
        <p>
          Any new version of this policy will be notified to customers and
          contacts via all reasonable means defined by the Entity, including
          electronically (e.g. notification via e-mail or online).
        </p>
        <h5 className="scroll-mt-8 text-xl font-bold text-secondary" id="26">
          26. For Further Information
        </h5>
        <p>
          For any further general information about personal data protection,
          please consult the CNIL website at{" "}
          <a
            className="text-primary underline"
            href="https://www.cnil.fr"
            target="_blank"
            rel="noreferrer"
          >
            www.cnil.fr
          </a>
          .
        </p>
      </div>
    </div>
  </Page>
);

export default PrivacyPolicy;
