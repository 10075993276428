import { useState } from "react";

import { useInterval } from "../hooks/useInterval";
import { useMediaQuery } from "../hooks/useMediaQuery";
import PartnerQuote, { Quote } from "./PartnerQuote";

import { ReactComponent as ArrowInCircle } from "../icons/arrowInCircle.svg";

const PartnerQuoteSlider = ({
  partnersQuotes,
}: {
  partnersQuotes: Quote[];
}) => {
  const [isDesktop] = useMediaQuery("(min-width: 768px)"); // md
  const [counter, setCounter] = useState(0);
  const [isSliderPaused, setIsSliderPaused] = useState(false);
  const sliderDelayMs = 5000;
  const isMobile = !isDesktop; // for consistency, we'll render based on isMobile
  useInterval(
    () => {
      if (isDesktop) setCounter((counter + 1) % partnersQuotes.length);
    },
    isSliderPaused ? null : sliderDelayMs
  );
  const previousSlide = () =>
    setCounter(counter - 1 < 0 ? partnersQuotes.length - 1 : counter - 1);
  const nextSlide = () =>
    setCounter(counter + 1 >= partnersQuotes.length ? 0 : counter + 1);
  const containerClasses =
    "flex flex-col items-center md:items-start md:flex-row";
  if (isMobile)
    return (
      <div className={containerClasses}>
        <PartnersQuotesWrapped {...{ partnersQuotes, counter }} />
        <div className="flex">
          <Previous clickHandler={previousSlide} />
          <Next clickHandler={nextSlide} />
        </div>
      </div>
    );
  return (
    <div
      className={containerClasses}
      onMouseEnter={() => setIsSliderPaused(true)}
      onMouseLeave={() => setIsSliderPaused(false)}
    >
      <Previous clickHandler={previousSlide} />
      <PartnersQuotesWrapped {...{ partnersQuotes, counter }} />
      <Next clickHandler={nextSlide} />
    </div>
  );
};

const PartnersQuotesWrapped = ({
  partnersQuotes,
  counter,
}: {
  partnersQuotes: Quote[];
  counter: number;
}) => (
  <div className="grid-stack grid">
    {partnersQuotes.map((partnersQuote, idx) =>
      idx === counter ? (
        <PartnerQuote key={partnersQuote.name} {...partnersQuote} />
      ) : (
        <div key={partnersQuote.name} className="opacity-0 ">
          <PartnerQuote {...partnersQuote} />
        </div>
      )
    )}
  </div>
);

const Previous = ({ clickHandler }: { clickHandler: () => void }) => (
  <span className="cursor-pointer select-none" onClick={clickHandler}>
    <ArrowInCircle className="mt-10 mr-10 h-10 w-10 rotate-90 justify-self-start active:text-gray-200 md:mt-28 lg:mt-20 lg:h-12 lg:w-12 xl:mt-28" />
  </span>
);

const Next = ({ clickHandler }: { clickHandler: () => void }) => (
  <span className="cursor-pointer select-none" onClick={clickHandler}>
    <ArrowInCircle className="mt-10 ml-10 h-10 w-10 -rotate-90 justify-self-start active:text-gray-200 md:mt-28 lg:mt-20 lg:h-12 lg:w-12 xl:mt-28" />
  </span>
);

export default PartnerQuoteSlider;
