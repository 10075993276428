const CheckBox = ({ required = false }: { required?: boolean }) => (
  <div className="inline-flex items-center">
    <label
      className="relative flex cursor-pointer items-center"
      htmlFor="checkbox"
    >
      <input
        id="checkbox"
        type="checkbox"
        className="peer relative h-5 w-5 cursor-pointer appearance-none rounded-sm border border-primary checked:border-primary checked:bg-primary checked:before:bg-primary hover:before:opacity-10"
        name="newsletter"
        value="yes"
        required={required}
      />
      <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 peer-checked:opacity-100">
        <svg
          className="h-3.5 w-3.5"
          viewBox="0 0 20 20"
          fill="currentColor"
          stroke="currentColor"
        >
          <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" />
        </svg>
      </div>
    </label>
  </div>
);

export default CheckBox;
