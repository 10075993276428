import { colors } from "../tailwind.config";

//
// Fixed dimensions
//
export const pictogramHeight = "h-12 sm:h-16";

//
// Paddings
//
export const sectionLeftPadding = "pl-5 sm:pl-8 lg:pl-24 xl:pl-40";
export const sectionRightPadding = "pr-5 sm:pr-8 lg:pr-24 xl:pr-40";
const sectionHorizontalPadding = `${sectionLeftPadding} ${sectionRightPadding}`;
export const sectionVerticalPadding = `py-12 sm:py-20`;
export const sectionPadding = `${sectionHorizontalPadding} ${sectionVerticalPadding}`;

//
// Colors
//
export type themeColor = keyof typeof colors;
export type tailwindTextColorClass =
  | "text-white"
  | "text-black"
  | `text-${themeColor}`
  | `text-[#${string}]`;

//
// Type utilities
//
export type WithRequiredProperties<T, K extends keyof T> = T & {
  [P in K]-?: T[P];
};

//
// Commons
// Note: as long as this section is short, we will keep things here.
// But if it grows too much, we will create a dedicated folder for it.
//

export type Corner = "br" | "bl" | "tl" | "tr";

export const getRoundingTailwindClass = (corners?: Corner | Corner[]) => {
  if (!corners) return "";
  let roundingClasses = "";
  const cornerArr = Array.isArray(corners) ? corners : [corners];
  for (const corner of cornerArr) {
    if (corner === "br")
      roundingClasses = `sm:rounded-br-4xl ${roundingClasses}`;
    else if (corner === "bl")
      roundingClasses = `sm:rounded-bl-4xl ${roundingClasses}`;
    else if (corner === "tl")
      roundingClasses = `sm:rounded-tl-4xl ${roundingClasses}`;
    else if (corner === "tr")
      roundingClasses = `sm:rounded-tr-4xl ${roundingClasses}`;
  }
  return roundingClasses;
};
