import TeamMemberPortrait, { Portrait } from "./TeamMemberPortrait";

export const TeamMemberPortraits = ({
  portraits,
}: {
  portraits: Portrait[];
}) => (
  <div className="grid place-content-center gap-16 px-12 sm:gap-12 sm:px-12 md:grid-cols-3 lg:gap-16 lg:px-20">
    {portraits.map((portrait) => (
      <TeamMemberPortrait {...portrait} key={portrait.photoUrl} />
    ))}
  </div>
);
