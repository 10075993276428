import ContactForm from "../components/ContactForm";
import DoubleColsHalfFilledSection from "../components/DoubleColsHalfFilledSection";
import Page from "../components/Page";
import TopSection from "../components/TopSection";
import { pictogramHeight } from "../utils";

import { ReactComponent as ContactUs } from "../icons/contactUs.svg";
import { ReactComponent as FindUs } from "../icons/findUs.svg";
import { ReactComponent as LinkedIn } from "../icons/linkedin.svg";
import { ReactComponent as Twitter } from "../icons/twitter.svg";

const Contact = () => (
  <Page>
    <div className="-mt-20"></div>
    <TopSection
      title="Contact"
      content="We are always happy to hear from you!"
    />
    <DoubleColsHalfFilledSection
      filled="right"
      leftChildren={contactUsSection}
      rightChildren={findUsSection}
    />
  </Page>
);

const contactUsSection = [
  <>
    <ContactUs className={`mb-5 fill-secondary ${pictogramHeight}`} />
    <h2 className="mb-8 text-2xl font-bold text-primary">Contact us</h2>
    <ContactForm />
  </>,
];

const findUsSection = [
  <>
    <FindUs className={`mb-5 fill-tertiary ${pictogramHeight}`} />
    <h2 className="mb-8 text-2xl font-bold text-white">
      You will find us here
    </h2>
    <div className="col-start-1 row-start-4 space-y-6 text-white md:col-start-2 md:row-start-2">
      <p className="font-semibold">Station F</p>
      <p className="text-lg font-semibold">
        5 Parvis Alan Turing,
        <br />
        75013 Paris
        <br />
        France
      </p>
      <p className="font-semibold">Biolabs Hôtel-Dieu</p>
      <p className="text-lg font-semibold">
        1 Parvis Notre-Dame,
        <br />
        75004 Paris
        <br />
        France
      </p>
      <div className="space-y-4">
        <p className="text-sm">You can also follow us there:</p>
        <div className="flex gap-4">
          <a
            href="https://www.linkedin.com/company/scienta-lab"
            aria-label="Link to Scienta Lab's Linkedin"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedIn className="h-6 fill-white sm:h-8" />
          </a>
          <a
            href="https://twitter.com/ScientaLab"
            aria-label="Link to Scienta Lab's Twitter"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter className="h-6 fill-white sm:h-8" />
          </a>
        </div>
      </div>
    </div>
  </>,
];

export default Contact;
