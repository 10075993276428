import { useRef } from "react";
import { Link } from "react-router-dom";

import { useRecaptchaForm } from "../hooks/useRecaptchaForm";
import { BtnOutline } from "./Buttons";
import { GoogleText } from "./GoogleText";

const ContactForm = () => {
  const contactForm = useRef<HTMLFormElement>(null);
  const { recaptchaState, formState, submitBehindCaptcha } =
    useRecaptchaForm(contactForm);

  return (
    <form
      ref={contactForm}
      onSubmit={submitBehindCaptcha}
      className="flex flex-col space-y-6"
    >
      <input
        className="hidden"
        type="text"
        name="type"
        value="contact"
        readOnly
      />
      <div>
        <p className="text-lg font-semibold text-primary">You are</p>
        <input
          className="w-full border-b border-b-black px-1 outline-none placeholder:text-sm placeholder:text-gray-400"
          placeholder="Name"
          type="text"
          name="name"
          required
        ></input>
      </div>
      <div>
        <p className="text-lg font-semibold text-primary">
          We can reach you out at
        </p>
        <input
          className="w-full border-b border-b-black px-1 outline-none placeholder:text-sm placeholder:text-gray-400"
          placeholder="Email adress"
          type="email"
          name="email"
          required
        ></input>
      </div>
      <div>
        <p className="text-lg font-semibold text-primary">Your message</p>
        <textarea
          className="h-40 w-full resize-none rounded-md border border-black p-1 outline-none placeholder:text-sm placeholder:text-gray-400"
          placeholder="Message"
          name="message"
          required
        ></textarea>
      </div>
      <BtnOutline
        type="submit"
        color="secondary"
        content={formState === "loading" ? "Sending..." : "Send"}
        disabled={formState === "loading"}
      />
      <div className="space-y-2">
        <GoogleText />
        {recaptchaState === "error" && (
          <p className="text-sm">
            Recaptcha script not loaded, please try to reload the page and
            submit the form again, or send an email at contact@scientalab.com to
            reach out.
          </p>
        )}
        {formState === "sent" && (
          <p className="text-sm">
            Thanks for the message. We'll be in touch shortly.
          </p>
        )}
        {formState === "error" && (
          <p className="text-sm">
            Something went wrong, please send an email at contact@scientalab.com
            to reach out.
          </p>
        )}
        <p className="text-xs text-gray-400">
          The data collected by Scienta Lab to respond to the data subject's
          request will be processed in accordance with the Personal Data
          Protection Policy for Customers and Prospects available{" "}
          <Link className="text-primary" to="/privacy-policy">
            here
          </Link>
          .
        </p>
      </div>
    </form>
  );
};

export default ContactForm;
