import { BtnOutline } from "./Buttons";
import Tag from "./Tag";
import { tailwindTextColorClass, themeColor } from "../utils";

import { ReactComponent as Location } from "../icons/location.svg";

// The Event name is taken in JS
const EventCard = ({
  date,
  color,
  tagTextColor,
  eventName,
  eventImgUrl,
  location,
  registrationUrl,
}: {
  date: string;
  color: themeColor;
  tagTextColor: tailwindTextColorClass;
  eventName: string;
  eventImgUrl: string;
  location: string;
  registrationUrl: string;
}) => (
  <div className="max-w-xs">
    <Tag content={date} color={color} tagTextColor={tagTextColor} />
    <h3 className={`mt-1 font-bold text-${color}`}>{eventName}</h3>
    <img
      className="my-7 w-full"
      src={eventImgUrl}
      alt={`Illustration of the ${eventName} event`}
      width="320px"
      height="180px"
    />
    <div className="mb-7 flex items-center">
      <Location className={`mr-3 h-8 fill-${color} sm:h-10`} />
      <p className="text-lg font-bold">{location}</p>
    </div>
    <a
      href={registrationUrl}
      target="_blank"
      rel="noreferrer"
      className="text-xl"
    >
      <BtnOutline content="Register here" color={color} />
    </a>
  </div>
);

export default EventCard;
