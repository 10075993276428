import { Navigate, useNavigate, useParams } from "react-router-dom";

import { PressReleaseData } from "../components/Article";
import BigSideSection from "../components/BigSideSection";
import Page from "../components/Page";
import Tag from "../components/Tag";
import TopSection from "../components/TopSection";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { sectionPadding } from "../utils";

import { ReactComponent as Arrow } from "../icons/arrow.svg";
import background from "../images/top-section-bg.webp";

const PressRelease = () => {
  const [isDesktop, hasIsDesktopChanged] = useMediaQuery("(min-width: 768px)"); // md
  const navigate = useNavigate();
  const shouldAnimationHappen = isDesktop && !hasIsDesktopChanged;
  const contactAddress = "contact@scientalab.com";
  const slug = useParams().slug;
  const pressRelease = pressReleases.find(
    (pressRelease) => pressRelease.slug === slug
  );
  if (!pressRelease) return <Navigate to="/404" />;
  const { title, date, tag, color, content } = pressRelease;
  const tagAnimationClasses = `${
    shouldAnimationHappen &&
    "md:animate-[appearFromRight_1s_linear_1_normal_forwards] md:opacity-0"
  }`;
  return (
    <Page>
      <div className="-mt-20"></div>
      <TopSection title="Press Release" />
      <div className={`${sectionPadding}`}>
        <div className="flex justify-center space-y-8">
          <article className="prose lg:prose-lg">
            <div className="grid-stack grid">
              <button
                onClick={() => navigate(-1)}
                className="mb-8 inline-flex h-fit flex-row items-center text-base underline"
              >
                <Arrow className="mr-2 inline w-3 rotate-180 " />
                Back to Newsroom
              </button>
              <div className="hidden w-1 -translate-x-8 animate-[expand_1s_ease-in-out_1] rounded-sm bg-gradient-to-b from-primary via-secondary to-tertiary md:block" />
              <h2 className="text-primary">{title}</h2>
            </div>
            <span className="inline-block space-x-2">
              <Tag
                content={date}
                color={color}
                tagTextColor="text-white"
                className={tagAnimationClasses}
              />
              {tag && (
                <Tag
                  content={tag}
                  color={color}
                  tagTextColor="text-white"
                  className={`md:[animation-delay:50ms] ${tagAnimationClasses}`}
                />
              )}
            </span>
            {content}
          </article>
        </div>
      </div>
      <BigSideSection backgroundImgUrl={background} fullContent>
        <h3 className="mb-8 text-4xl font-bold">Get in touch</h3>
        <p>
          For media inquiries, please reach out to{" "}
          <a
            className="underline underline-offset-4"
            href={`mailto:${contactAddress}`}
          >
            {contactAddress}
          </a>
        </p>
      </BigSideSection>
      <div className="sm:mb-20"></div>
    </Page>
  );
};

export const pressReleases: PressReleaseData[] = [
  {
    title:
      "Scienta Lab Unveils Cutting-edge AI Model Enhancing Primary Sjögren's Syndrome Diagnosis at EULAR 2023",
    slug: "eular-sjogrens-syndrome-diagnosis",
    date: "26/05/2023",
    color: "tertiary",
    tag: "EN",
    tagTextColor: "text-[#0e1f21]",
    content: (
      <div>
        <p className="lead">#artificialintelligence #primarysjogrensyndrome</p>
        <p>
          Scienta Lab, is a pioneering deeptech company modeling immune-mediated
          inflammatory diseases through an explicable machine learning platform.
          Scienta Lab's research collaboration with Pr. Mariette and Dr. Bitoun
          (Bicêtre Rheumatology Department, AP-HP - Paris, France) on the
          diagnosis of primary Sjögren's syndrome will be presented at the 2023
          European Union League Against Rheumatism (EULAR) Annual Congress in
          Milan, Italy. This project was conducted within the IMI funded
          NECESSITY consortium, gathering a group of academics, industry and
          patient organizations dedicated to better understanding Sjögren's
          syndrome.
        </p>
        <p>
          Primary Sjögren syndrome (pSS) is a rare chronic autoimmune disease
          that primarily affects glands producing tears and saliva, causing
          symptoms such as intense fatigue, chronic pain, dry eyes and mouth.
          The syndrome can also affect other parts of the body, including
          joints, skin, and vital organs such as the lungs and kidneys; and is
          known to be linked with serious co- morbidities such as cancer (more
          than 10% of patients with primary Sjögren's Syndrome will develop
          lymphoma).
        </p>
        <p>
          Accurately diagnosing pSS remains challenging, as it relies on a
          combination of multiple criteria such as the analysis of biopsies,
          assessment of autoantibody status, and evaluation of ocular and oral
          dryness levels. The analysis of salivary gland biopsies aims at
          measuring the extent of lymphocyte infiltration, which is expressed by
          pathologists in a score known as the focus score. A focus score equal
          to or greater than 1 serves as a definitive histological criterion for
          confirming pSS diagnosis; therefore, its accurate evaluation plays a
          pivotal role in pSS diagnosis. However, this evaluation requires
          specialized expertise, making it difficult for non- specialized
          medical centers to achieve consistent results - studies have shown a
          reclassification rate of up to 53% when expert rescoring is performed.
        </p>
        <p>
          In this context, Scienta Lab developed an AI model capable of
          predicting the focus score and diagnosis of pSS with high accuracy
          using only digital scans of biopsy slides.
        </p>
        <blockquote>
          <p className="!mb-1">
            With our expertise in Immunology & inflammation coupled with machine
            learning capabilities, we were able to develop an automatized and
            normalized grading of lymphocyte aggregates via machine learning to
            considerably enhance diagnosis reliability. The resulting technology
            has not only the potential to transform clinical care, but also drug
            development by supporting the identification of histological
            biomarkers associated with pSS diagnosis. I'd like to thank all
            NECESSITY participating members for their support along this
            collaborative research project.
          </p>
          <b>- Vincent Bouget, CSO</b>
        </blockquote>
        <p>
          Further clinical evaluation in real-world clinical practice along with
          thorough validation amongst a broader group of individuals will be
          needed before routine use in clinical and research activities. Scienta
          Lab is actively engaged in progressing towards these next steps, and
          ultimately aims at identifying new biomarkers linked to pSS in
          salivary gland biopsies to improve patient therapeutic management.
        </p>
        <blockquote>
          <p className="!mb-1">
            The algorithm we developed only uses real world data that is already
            gathered during clinical practice. This allowed the team to get rid
            of time consuming, expensive, and hard to manage human labeling
            pipelines that have been the standard so far in deep learning
            assisted pathology. This means that we can easily scale to orders of
            magnitude of additional data in the future to solidify our current
            results, more accurately help pathologists and make new discoveries
            concerning histological biomarkers. This was made possible because
            we leveraged semi-supervised learning and self-supervised learning
            concurrently. Definitely a first in AI assisted pathology for
            Sjögren.
          </p>
          <b>
            - Louis Basseto, Research Scientist, in charge of the technical
            development
          </b>
        </blockquote>
        <p className="lead">
          Deep Learning Accurately Predicts Focus Score and Diagnosis of Primary
          Sjögren's Syndrome Using Labial Salivary Gland Biopsies
        </p>
        <b>Presentation time & Place</b>
        <ul className="!mt-0">
          <li>Friday, June 2, 2023, 11:35 AM CEST</li>
          <li>
            MiCo Convention Center, South Hall, Session room 1 (Milano, Italy)
          </li>
        </ul>
      </div>
    ),
  },
];

export default PressRelease;
