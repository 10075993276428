import { ReactComponent as Linkedin } from "../icons/linkedin.svg";

export type Portrait = {
  photoUrl: string;
  name: string;
  position: string;
  content?: string;
  linkedinUrl?: string;
};

const TeamMemberPortrait = ({
  photoUrl,
  name,
  position,
  content,
  linkedinUrl,
}: Portrait) => {
  return (
    <div className="flex max-w-[260px] flex-1 flex-col items-center justify-between gap-6 text-center">
      <div className="space-y-4">
        <img
          className="rounded-full"
          src={photoUrl}
          alt={`Portrait of ${name}`}
          width="320px"
          height="320px"
        />
        <div className="space-y-2">
          <h3 className="font-semibold text-primary">{name}</h3>
          <h4 className="font-medium text-secondary">{position}</h4>
        </div>
        {content && <p className="break-words">{content}</p>}
      </div>
      {linkedinUrl && (
        <a
          href={linkedinUrl}
          aria-label={`Link to ${name}'s Linkedin`}
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin className="h-6 fill-[#4875b4] sm:h-8" />
        </a>
      )}
    </div>
  );
};

export default TeamMemberPortrait;
