import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouteObject,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";

import "./index.css";
import reportWebVitals from "./reportWebVitals";

import AboutUs from "./pages/AboutUs";
import Companies from "./pages/Companies";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Newsroom from "./pages/Newsroom";
import NotFound from "./pages/NotFound";
import Partners from "./pages/Partners";
import Patients from "./pages/Patients";
import PressRelease from "./pages/PressRelease";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Researchers from "./pages/Researchers";
import Technology from "./pages/Technology";
import TermsAndConditions from "./pages/TermsAndConditions";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const pagesInNav: NamedRouteObject[] = [
  { name: "Home", path: "/", element: <Home /> },
  { name: "Technology", path: "/technology", element: <Technology /> },
  { name: "About us", path: "/about-us", element: <AboutUs /> },
  { name: "Newsroom", path: "/newsroom", element: <Newsroom /> },
  { name: "Partnerships", path: "/partners", element: <Partners /> },
  { name: "Contact", path: "/contact", element: <Contact /> },
];

const Root = () => (
  <>
    <Outlet />
    <ScrollRestoration />
  </>
);

const router = createBrowserRouter([
  {
    name: "root",
    path: "/",
    element: <Root />,
    children: [
      ...pagesInNav,
      {
        name: "Privacy Policy",
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        name: "Terms And Conditions",
        path: "/terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        name: "Press Release",
        path: "/press-release/:slug",
        element: <PressRelease />,
      },
      {
        name: "Partners",
        path: "/partners",
        element: <Partners />,
      },
      {
        name: "Patients",
        path: "/patients",
        element: <Patients />,
      },
      {
        name: "Researchers",
        path: "/researchers",
        element: <Researchers />,
      },
      {
        name: "Companies",
        path: "/companies",
        element: <Companies />,
      },
      { name: "Not Found", path: "/404", element: <NotFound /> },
      {
        name: "default",
        path: "*",
        element: <Navigate to="/404" replace={true} />,
      },
    ],
  },
] as NamedRouteObject[]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

type NamedRouteObject = RouteObject & {
  name?: string;
  path: string;
};
