import { useMemo, useState } from "react";

import { useMediaQuery } from "../hooks/useMediaQuery";

const Headband = () => {
    const [isDesktop] = useMediaQuery("(min-width: 865px)");
    const [_, setIsOpen] = useState<boolean>(false);
  
    // We use useMemo here to be able to close the mobile nav if width changes. This way,
    // if users open the nav, increase the width to the point they switch to desktop nav, then
    // if they switch back to mobile, the nav will be closed.
    const isMobile = useMemo(() => {
      setIsOpen(false);
      return !isDesktop;
    }, [isDesktop]);
    return isMobile ? (null) : (
    <div className="bg-tertiary text-black text-center py-6">
        <p className="text-lg">
        Breaking news : Scienta Lab announces its €4M Seed funding to pioneer the 
AI-powered Precision Immunology revolution <a className="underline" href="https://www.usine-digitale.fr/article/scienta-lab-leve-4-millions-d-euros-pour-son-modele-de-fondation-dedie-a-la-recherche-en-immunologie.N2203248">[Read more]</a>
        </p>
    </div>
    )
}

export default Headband;