import BigSideSection from "../components/BigSideSection";
import DoubleColsHalfFilledSection, {
  cancelLeftColRightPaddingsStartingAtSm,
  cancelRightColLeftPaddingsStartingAtSm,
} from "../components/DoubleColsHalfFilledSection";
import Page from "../components/Page";
import TopSection from "../components/TopSection";
import { pictogramHeight, sectionPadding } from "../utils";

import { ReactComponent as AIModels } from "../icons/AIModels.svg";
import { ReactComponent as ApplicableInformation } from "../icons/applicableInformation.svg";
import { ReactComponent as Applications } from "../icons/applications.svg";
import { ReactComponent as Arrow } from "../icons/arrow.svg";
import { ReactComponent as BiologyData } from "../icons/biologyData.svg";
import { ReactComponent as ClinicalStrategies } from "../icons/clinicalStrategies.svg";
import { ReactComponent as Data } from "../icons/data.svg";
import { ReactComponent as Drug } from "../icons/drug.svg";
import { ReactComponent as ExplainableAI } from "../icons/explainableAI.svg";
import { ReactComponent as Hexagon } from "../icons/hexagon.svg";
import Logo from "../icons/Logo";
import { ReactComponent as XAI } from "../icons/xai.svg";
import background from "../images/top-section-bg.webp";

const Technology = () => (
  <Page>
    <div className="-mt-20"></div>
    <TopSection title="Technology" />
    <div className={`${sectionPadding}`}>
      <ClinicalStrategies
        className={`col-span-full row-start-1 mb-5 fill-secondary ${pictogramHeight}`}
      />
      <div className="space-y-8">
        <h2 className="text-2xl font-bold text-primary">
          Closing the gap between raw data and personalized medicine
        </h2>
        <p>
          The Scienta Lab proprietary platform combines multimodal immunology
          datasets, machine learning algorithms and medical expertise into an
          integrated map of the immuno-inflammation physiopathology.
        </p>
        <p>
          By linking datasets of various modalities together, our platform can
          unravel data-driven models to support drug development and clinical
          strategies.
        </p>
      </div>
      <div className="flex justify-center">
        <Schema />
      </div>
    </div>
    <BigSideSection backgroundImgUrl={background}>
      <ExplainableAI
        className={`col-span-full row-start-1 mb-5 fill-tertiary ${pictogramHeight}`}
      />
      <h2 className="mb-8 text-2xl font-bold">Focus on explainable AI</h2>
      <p className="mb-12">
        With their very high level of abstraction and complex internal
        processes, neural networks are often described as "black boxes"
        traditionally very difficult for human minds to understand. But finding
        new correlations between data and outputs demands a clear understanding
        of this technology coupled with clinical expertise.
      </p>
      <p className="text-lg font-bold">
        To answer these challenges, we developed a toolkit of explainability
        solutions in order to translate algorithmic signals into clinically
        activable information.
      </p>
      <div className="sm:mb-36"></div>
    </BigSideSection>
    <div className="sm:-mb-20"></div>
    <DoubleColsHalfFilledSection
      staggered
      filled="left"
      roundedCorners="tr"
      leftChildren={[
        <>
          <Applications
            className={`col-span-full row-start-1 mb-5 fill-tertiary ${pictogramHeight}`}
          />
          <h2 className="mb-8 text-2xl font-bold text-white">Applications</h2>
        </>,
        <LeftPill
          n={1}
          title="Diagnostic models"
          content="To confirm or refute the presence of a disease."
        />,
        <div className="mb-5 sm:mb-8"></div>,
        <LeftPill
          n={2}
          title="Prognosis models"
          content="To predict the course of diseases."
        />,
        <div className="mb-5 sm:mb-8"></div>,
        <LeftPill
          n={3}
          title="Response to treatment models"
          content="To predict the effect of a therapy at the patient level."
        />,
      ]}
      rightChildren={[
        <div className="hidden"></div>,
        <RightPill
          applications={[
            "Support patient diagnosis",
            "Personalize treatment plan",
          ]}
        />,
        <div className="mb-5 sm:mb-8"></div>,
        <RightPill
          applications={["Clinical trials enrichment", "Time-to-drop analysis"]}
        />,
        <div className="mb-5 sm:mb-8"></div>,
        <RightPill
          applications={[
            "Support clinical trial inclusion and stratification",
            "Select the best treatment amongst different options in clinical practice",
          ]}
        />,
      ]}
    />
  </Page>
);

const LeftPill = ({
  n,
  title,
  content,
}: {
  n: number;
  title: string;
  content: string;
}) => (
  <div
    className={`h-full rounded-t-4xl border-2 border-b-0 border-tertiary pb-0 pt-5 pl-5  pr-10 text-white sm:rounded-l-4xl sm:rounded-tr-none sm:border-b-2 sm:border-r-0 sm:pb-5 ${cancelLeftColRightPaddingsStartingAtSm}`}
  >
    <div className="grid grid-cols-[min-content,1fr] grid-rows-[min-content,min-content] items-center ">
      <span className="mr-5 grid h-8 w-8 place-content-center rounded-full border-2 border-tertiary text-lg font-bold text-tertiary sm:h-10 sm:w-10 sm:text-xl">
        {n}
      </span>
      <h3 className="text-lg font-bold uppercase text-tertiary [grid-column:2] sm:text-xl ">
        {title}
      </h3>
      <p className="mt-4 font-bold [grid-column:2]">{content}</p>
    </div>
  </div>
);

const RightPill = ({ applications }: { applications: string[] }) => (
  <div
    className={`h-full rounded-b-4xl border-2 border-t-0 border-tertiary py-5 pl-[calc(2rem+40px)] pr-5 sm:rounded-r-4xl sm:rounded-bl-none sm:border-t-2 sm:border-l-0 sm:border-primary sm:pl-10 ${cancelRightColLeftPaddingsStartingAtSm}`}
  >
    <h3 className="text-lg font-bold text-white sm:text-xl sm:leading-10 sm:text-primary">
      Applications:
    </h3>
    <ul className="mt-4 space-y-2 text-white sm:text-black">
      {applications.map((application) => (
        <li key={application}>
          <Arrow className="mr-1 inline-block h-4 -translate-y-[1px] fill-tertiary sm:fill-primary" />
          {application}
        </li>
      ))}
    </ul>
  </div>
);

const Schema = () => (
  <div className="mt-12 grid grid-cols-[max-content,1fr] grid-rows-[repeat(6,min-content)] md:grid-cols-[1fr,341px,1fr] md:grid-rows-[226px,30px,196px,30px,226px]">
    {/* Number and main text */}
    <div className="ml-5 mb-5 grid h-full w-full grid-rows-[min-content,min-content] [grid-column:2] [grid-row:1] md:mb-0 md:ml-0 md:grid-rows-[77px,1fr] md:[grid-column:1]">
      <span className="mt-7 hidden h-7 w-7 place-content-center place-self-start rounded-full border-2 border-primary font-bold text-primary [grid-row:1] md:mt-0 md:flex md:place-self-end">
        1
      </span>
      <div className="md:text-right">
        <h3 className="text-lg font-bold text-primary">Real world data</h3>
        <p className="inline-block max-w-[200px]">
          Clinical, biology, pathology, imaging, omics.
        </p>
      </div>
    </div>
    <div className="ml-5 mb-5 grid h-full w-full grid-rows-[min-content,min-content] [grid-column:2] [grid-row:3] md:mb-0 md:ml-0 md:grid-rows-[77px,1fr] md:[grid-row:2] md:[grid-column:1]">
      <span className="mt-7 hidden h-7 w-7 place-content-center place-self-start rounded-full border-2 border-tertiary font-bold text-tertiary [grid-row:1] md:mt-0 md:flex md:place-self-end">
        2
      </span>
      <div className="md:text-right">
        <h3 className="text-lg font-bold text-tertiary">Modelling</h3>
        <p className="inline-block max-w-[200px]">
          Algorithms for diagnosis, prognosis and treatment outcomes prediction.
        </p>
      </div>
    </div>
    <div className="ml-5 mb-5 grid h-full w-full grid-rows-[min-content,min-content] [grid-column:2] [grid-row:5] md:mb-0 md:ml-0 md:grid-rows-[77px,1fr] md:[grid-row:4] md:[grid-column:1]">
      <span className="mt-7 hidden h-7 w-7 place-content-center place-self-start rounded-full border-2 border-secondary font-bold text-secondary [grid-row:1] md:mt-0 md:flex md:place-self-end">
        3
      </span>
      <div className="md:text-right">
        <h3 className="text-lg font-bold text-secondary">
          Applicable Information
        </h3>
        <p className="inline-block max-w-[200px]">
          To improve clinical development and clinical strategies.
        </p>
      </div>
    </div>
    {/* Hexagons */}
    <div className="grid-stack z-20 grid w-full [grid-column:1] [grid-row:1/3] md:[grid-row:1/3] md:[grid-column:2]">
      <div className="hidden h-0.5 w-32 translate-y-[63px] bg-primary md:block"></div>
      <div className="grid-stack grid place-self-start md:place-self-center">
        <Hexagon className="h-20 place-self-center fill-primary md:h-64" />
        <BiologyData className="h-9 place-self-center fill-white md:h-32" />
      </div>
      <div className="hidden h-0.5 w-32 -translate-y-[66px] rotate-[30deg] place-self-end bg-primary md:block"></div>
    </div>
    <div className="grid-stack z-10 grid w-full [grid-column:1] [grid-row:3/5] md:[grid-row:2/5] md:[grid-column:2]">
      <div className="hidden h-0.5 w-32 translate-y-[63px] bg-tertiary md:block"></div>
      <div className="grid-stack grid place-self-start md:place-self-center">
        <Hexagon className="h-20 place-self-center fill-tertiary md:h-64" />
        <div className="flex flex-col items-center gap-3 place-self-center">
          <XAI className="h-9 place-self-center fill-primary md:h-12" />
          <span className="hidden rounded-full bg-white py-2 px-3 md:inline-block">
            <Logo className="h-8 fill-black" />
          </span>
        </div>
      </div>
      <div className="hidden h-0.5 w-32 -translate-y-[60px] -rotate-[30deg] place-self-end bg-tertiary md:block"></div>
    </div>
    <div className="grid-stack grid w-full [grid-column:1] [grid-row:5/7] md:[grid-row:4/6] md:[grid-column:2]">
      <div className="hidden h-0.5 w-32 translate-y-[63px] bg-secondary md:block"></div>
      <div className="grid-stack grid place-self-start md:place-self-center">
        <Hexagon className="h-20 place-self-center fill-secondary md:h-64" />
        <ApplicableInformation className="h-9 place-self-center fill-white md:h-32" />
      </div>
      <div className="hidden h-0.5 w-32 -translate-y-[60px] -rotate-[30deg] place-self-end bg-secondary md:block"></div>
    </div>
    {/* Icons and secondary texts */}
    <div className="ml-5 mb-12 grid grid-cols-[min-content,1fr] place-content-end [grid-row:2] [grid-column:2] md:mb-0 md:ml-0 md:translate-y-3 md:[grid-column:3]">
      <Data className={`fill-primary ${pictogramHeight} place-self-end`} />
      <p className="ml-4 max-w-[140px] text-sm font-bold text-primary">
        We leverage the power of real world data...
      </p>
    </div>
    <div className="ml-5 mb-12 grid grid-cols-[min-content,1fr] place-content-end [grid-column:2] [grid-row:4] md:mb-0 md:ml-0 md:-translate-y-12 md:[grid-row:3] md:[grid-column:3]">
      <AIModels className={`fill-tertiary ${pictogramHeight} place-self-end`} />
      <p className="ml-4 max-w-[140px] text-sm font-bold text-tertiary">
        ... and build multimodal explainable AI models...
      </p>
    </div>
    <div className="ml-5 mb-12 grid grid-cols-[min-content,1fr] place-content-end [grid-column:2] [grid-row:6] md:mb-0 md:ml-0 md:-translate-y-20 md:[grid-row:5] md:[grid-column:3]">
      <Drug className={`fill-secondary ${pictogramHeight} place-self-end`} />
      <p className="ml-4 max-w-[140px] text-sm font-bold text-secondary">
        ... to enhance drug development and clinical strategies.
      </p>
    </div>
  </div>
);

export default Technology;
