import { ReactComponent as ArrowInCircle } from "../icons/arrowInCircle.svg";
import background from "../images/top-section-bg.webp";

const TopSection = ({
  title,
  content,
  size = "small",
}: {
  title: string;
  content?: React.ReactNode;
  size?: "small" | "large";
}) => (
  <div
    className={`grid grid-rows-[1fr,max-content] justify-items-center overflow-hidden bg-cover text-white ${
      size === "small" ? "min-h-[40vh]" : "min-h-[75vh]"
    }`}
    style={{ backgroundImage: `url(${background})` }}
  >
    <div className="mt-20 flex w-full flex-col items-center justify-center p-5 text-center tracking-wider sm:w-5/6 md:w-4/5 xl:w-3/5">
      <h1 className="text-3xl font-semibold uppercase sm:text-4xl md:text-5xl lg:text-[3.75rem]">
        {title}
      </h1>
      {content && <p className="mt-10 md:text-lg lg:text-xl">{content}</p>}
    </div>
    <ArrowInCircle className="mb-10 mt-6 h-10 animate-bounce self-end fill-tertiary [animation-duration:2500ms] sm:h-12" />
  </div>
);

export default TopSection;
