import DoubleColsHalfFilledSection from "../components/DoubleColsHalfFilledSection";
import Page from "../components/Page";
import TopSection from "../components/TopSection";
import { pictogramHeight } from "../utils";

import { ReactComponent as LegalNotice } from "../icons/legalNotice.svg";

const TermsAndConditions = () => (
  <Page>
    <div className="-mt-20"></div>
    <TopSection title="Terms & Conditions" />
    <DoubleColsHalfFilledSection
      filled="right"
      leftChildren={editorSection}
      rightChildren={publicationDirectorAndHostingSection}
    />
  </Page>
);

const editorSection = [
  <>
    <LegalNotice className={`content mb-5 fill-secondary ${pictogramHeight}`} />
    <h2 className="mb-8 text-2xl font-bold text-primary">Terms of use</h2>
  </>,
  <>
    <div className="space-y-8">
      <h5 className="text-xl font-semibold text-secondary">Editor</h5>
      <p>The website www.scientalab.com is owned and controlled by:</p>
      <p>
        Scienta Lab, a French <i>“Société par Actions Simplifiée"</i> whose head
        office is located at 3 rue Joliot Curie – Gif-Sur-Yvette (91190), France
      </p>
      <p>
        And registered under the number 900 344 748 with the Registry of Trade
        and Companies of Evry.
      </p>
    </div>
  </>,
];

const publicationDirectorAndHostingSection = [
  <div></div>,
  <div className="space-y-8 text-white">
    <h5 className="text-xl font-semibold text-tertiary">
      Director of Publication
    </h5>
    <p>Ms Camille Bouget, president</p>
    <h5 className="text-xl font-semibold text-tertiary">Website host</h5>
    <p>
      The website www.scientalab.com is hosted on European located servers of
      Amazon Web Services Inc, PO Box 81226, Seattle, WA 981808-1226 - USA.
    </p>
    <a
      href="https://aws.amazon.com/fr/compliance/eu-data-protection/"
      className="inline-block break-all"
      target="blank"
      rel="noreferrer"
    >
      https://aws.amazon.com/fr/compliance/eu-data-protection/
    </a>
  </div>,
];

export default TermsAndConditions;
