import Footer from "./Footer";
import Nav from "./Nav";
import Headband from "./Headband";

const Page = ({ children, ...props }: React.ComponentProps<"div">) => {
  return (
    <div {...props}>
      <Headband />
      <Nav />
      {children}
      <Footer />
    </div>
  );
};

export default Page;
