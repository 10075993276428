import { FormEvent, useEffect, useState } from "react";

// Extends window interface with optional grecaptcha
// to make TypeScript happy
declare global {
  interface Window {
    grecaptcha?: Record<string, any | undefined>;
  }
}

const siteKey = "6LcKMCoeAAAAAM0tO_GweVwD2xTStcht46YzpWjn";
const formHandlerLambdaUrl = process.env.REACT_APP_CONTACT_FORM_LAMBDA_URL;

export const useRecaptchaForm = (formRef: React.RefObject<HTMLFormElement>) => {
  const [recaptchaState, setRecaptchaState] = useState<
    "loading" | "loaded" | "error"
  >("loading");
  const [formState, setFormState] = useState<
    "idle" | "loading" | "sent" | "error"
  >("idle");

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "recaptcha";
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
    script.async = true;
    script.onload = () => setRecaptchaState("loaded");
    script.onerror = () => setRecaptchaState("error");
    document.body.appendChild(script);
    return () => {
      document.getElementById("recaptcha")?.remove();
    };
  }, []);

  const submitBehindCaptcha = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (window.grecaptcha === undefined) return setFormState("error");
    if (formHandlerLambdaUrl === undefined) {
      console.error(
        "REACT_APP_CONTACT_FORM_LAMBDA_URL env var should contain the URL to the lambda function dealing with the contact form data."
      );
      return setFormState("error");
    }
    setFormState("loading");
    const formData = getFormDataFromFormEvent(event);
    window.grecaptcha
      .execute(siteKey, { action: "submit" })
      .then((token: string) => {
        fetch(formHandlerLambdaUrl, {
          method: "POST",
          body: JSON.stringify({
            ...formData,
            "g-recaptcha-response": token,
          }),
        })
          .then(async (response) => {
            const isResOk = response.status === 200;
            setFormState(isResOk ? "sent" : "error");
            const result = await response.json();
            if (!isResOk) {
              const error = new Error(result.message);
              error.name = result.name;
              throw error;
            }
            if (response.status === 200) formRef.current?.reset();
            console.log(result);
          })
          .catch((err) => {
            console.error(err, err.message);
            setFormState("error");
          });
      });
  };

  return { recaptchaState, formState, submitBehindCaptcha };
};

const getFormDataFromFormEvent = (event: FormEvent<HTMLFormElement>) => {
  const formData: Record<string, string> = {};
  for (const input of Array.from(event.currentTarget.elements)) {
    const { name, value, checked } = input as any;
    if (name.length === 0) continue;
    if (name === "newsletter") {
      formData[name] = checked === true ? "yes" : "no";
      continue;
    }
    formData[name] = value;
  }
  return formData;
};
