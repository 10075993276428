import { tailwindTextColorClass, themeColor } from "../utils";

const Tag = ({
  content,
  color,
  tagTextColor,
  className = "",
  ...props
}: {
  content: string;
  color: themeColor | "white";
  tagTextColor: tailwindTextColorClass;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={`inline-flex rounded-full px-3 py-1 bg-${color} ${tagTextColor} ${className}`}
    {...props}
  >
    <span
      className={`inline-block h-[13px] text-base font-semibold leading-[16px]`}
    >
      {content}
    </span>
  </div>
);

export default Tag;
