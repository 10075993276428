import { Link } from "react-router-dom";

import { ReactComponent as LinkedIn } from "../icons/linkedin.svg";
import Logo from "../icons/Logo";
import { ReactComponent as Twitter } from "../icons/twitter.svg";

const Footer = () => {
  return (
    <div className="flex flex-col gap-6 bg-secondary px-10 pt-12 pb-6 text-white sm:gap-16">
      <div className="flex flex-col items-center justify-between gap-6 sm:flex-row sm:gap-0">
        <Logo className="h-6 fill-white sm:h-10" fillAffectsGradients />
        <div className="flex items-center gap-4">
          <a
            href="https://www.linkedin.com/company/scienta-lab"
            aria-label="Link to Scienta Lab's Linkedin"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedIn className="h-6 fill-white sm:h-8" />
          </a>
          <a
            href="https://twitter.com/ScientaLab"
            aria-label="Link to Scienta Lab's Twitter"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter className="h-6 fill-white sm:h-8" />
          </a>
        </div>
      </div>
      <div className="flex flex-col justify-center text-center text-sm sm:gap-2 sm:text-base md:flex-row [&_a]:underline [&_a]:underline-offset-4">
        <p className="pb-2 sm:pb-0">Copyright Scienta Lab 2023</p>
        <p className="hidden md:inline">-</p>
        <Link className="py-2 sm:py-0" to="/terms-and-conditions">
          Terms and conditions
        </Link>
        <p className="hidden md:inline">-</p>
        <Link className="py-2 sm:py-0" to="/privacy-policy">
          Privacy policy
        </Link>
      </div>
    </div>
  );
};

export default Footer;
