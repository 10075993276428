import { Link } from "react-router-dom";
import BigSideSection from "../components/BigSideSection";
import { BtnOutline } from "../components/Buttons";
import DoubleColsHalfFilledSection from "../components/DoubleColsHalfFilledSection";
import NewsletterForm from "../components/NewsletterForm";
import Page from "../components/Page";
import TopSection from "../components/TopSection";
import { pictogramHeight } from "../utils";

import { ReactComponent as DrugDevelopment } from "../icons/drugDevelopment.svg";
import { ReactComponent as Partnering } from "../icons/partnering.svg";
import { ReactComponent as TeamInnovators } from "../icons/teamInnovators.svg";
import background from "../images/top-section-bg.webp";

const Home = () => (
  <Page>
    <div className="-mt-20"></div>
    <TopSection
      title="Redefining precision immunology"
      content="we are modelling immune-mediated inflammatory diseases in an explainable machine learning platform to pave the way for personalized patient care worldwide"
      size="large"
    />
    <DoubleColsHalfFilledSection
      leftChildren={pioneeringSection}
      rightChildren={variabilitySection}
      filled="right"
      roundedCorners="bl"
    />
    <div className="sm:-mt-28"></div>
    <BigSideSection backgroundImgUrl={background}>
      <PartneringSection />
      <div className="sm:mb-40"></div>
    </BigSideSection>
    <div className="sm:-mb-20"></div>
    <DoubleColsHalfFilledSection
      leftChildren={multidisciplinarySection}
      rightChildren={[<div></div>, <NewsletterForm />]}
      filled="left"
      roundedCorners="tr"
    />
  </Page>
);

const pioneeringSection = [
  <DrugDevelopment
    className={`col-span-full row-start-1 mb-5 fill-secondary ${pictogramHeight}`}
  />,
  <div className="space-y-8">
    <h2 className="text-2xl font-bold text-primary">
      Pioneering a data driven approach to drug development and clinical
      strategies
    </h2>
    <p>
      Clinical trials with highly controlled populations and settings remain the
      gold standard to evaluate the efficacy of new therapies.
    </p>
    <p className="text-secondary">
      As reliable as these trials are, they fail to capture a key element for
      predicting real-life effectiveness:{" "}
      <span className="underline">individual variability</span>.
    </p>
    <p>
      In the complex field of immuno-inflammation many factors can influence the
      clinical benefit of a treatment.
    </p>
  </div>,
];

const variabilitySection = [
  <div></div>,
  <div className="flex flex-col space-y-8 text-white">
    <p className="text-xl font-bold">
      This variability leads to various questions such as
    </p>
    <p>
      Among all treatments available for rheumatoid arthritis, which one has the
      highest probability of success for patient x?
    </p>
    <p>
      Why do some patients with lupus show a very high response of drug y, and
      others don't?
    </p>
    <p>
      Which subgroup of patients with Crohn's disease are more likely to benefit
      from new drug z?
    </p>
    <p>
      By incorporating our explainable machine learning platform with global
      medical networks, we are pioneering an innovative data-driven approach to
      answer these questions.
    </p>
    <Link to="/technology">
      <BtnOutline color="tertiary" content="Discover our technology" />
    </Link>
  </div>,
];

const PartneringSection = () => (
  <>
    <Partnering
      className={`col-span-full row-start-1 mb-5 fill-tertiary ${pictogramHeight}`}
    />
    <h2 className="mb-8 text-2xl font-bold">
      Partnering with bold visionaries to transform precision medicine in
      immunology
    </h2>
    <p className="mb-12">
      To realize our mission, we build strategic partnerships with academic
      centers, researchers and industry leaders at various stages of the drug
      development, approval and clinical use.
    </p>
    <p className="mb-8 text-lg font-bold">
      Interested in joining our network of partners ?
    </p>
    <Link to="/partners">
      <BtnOutline color="tertiary" content="Fill our contact form" />
    </Link>
  </>
);

const multidisciplinarySection = [
  <TeamInnovators
    className={`col-span-full row-start-1 mb-5 fill-tertiary ${pictogramHeight}`}
  />,
  <div className="space-y-8 text-white">
    <h2 className="mb-8 text-2xl font-bold">
      A multidisciplinary team of innovators united around a strong vision
    </h2>
    <p>
      Scienta Lab was founded with the conviction that machine learning has the
      potential to support the develpment of a new paradigm for patients with
      immuno-inflammatory conditions.
    </p>
    <p>
      We continue to build a multidisciplinary team of outstanding, curious and
      honest individuals working passionately towards this goal.
    </p>
    <Link to="/about-us" className="inline-block">
      <BtnOutline color="tertiary" content="Learn more about us" />
    </Link>
  </div>,
];

export default Home;
