import { useEffect, useState } from "react";

export const useMediaQuery = (query: string) => {
  const [isQueryMatched, setIsQueryMatched] = useState<boolean>(
    window.matchMedia(query).matches
  );
  const [hasIsQueryMatchedChanged, setHasIsQueryMatchedChanged] =
    useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsQueryMatched(window.matchMedia(query).matches);
      setHasIsQueryMatchedChanged(true);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [query]);
  return [isQueryMatched, hasIsQueryMatchedChanged];
};
