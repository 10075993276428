import Page from "../components/Page";
import background from "../images/top-section-bg.webp";

const NotFound = () => (
  <Page>
    <div className="-mt-20"></div>
    <div className="grid-stack grid">
      <img
        src={background}
        className="h-screen min-h-fit w-full object-cover"
        alt=""
      />
      <div className="place-self-center text-white">
        <p className="text-3xl uppercase md:text-8xl">Sorry</p>
        <p className="text-xl md:text-4xl">We couldn't find that page</p>
      </div>
    </div>
  </Page>
);

export default NotFound;
