import { useRef } from "react";
import { Link } from "react-router-dom";

import { BtnOutline } from "../components/Buttons";
import CheckBox from "../components/Checkbox";
import { GoogleText } from "../components/GoogleText";
import Page from "../components/Page";
import TopSection from "../components/TopSection";
import { useRecaptchaForm } from "../hooks/useRecaptchaForm";

const Researchers = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const { recaptchaState, formState, submitBehindCaptcha } =
    useRecaptchaForm(formRef);

  return (
    <Page className="flex flex-col">
      <div className="-mt-20"></div>
      <TopSection
        title="Researchers"
        content={
          <>
            Interested in joining the movement?
            <br />
            Enter your details below, and our team will reach out to you
          </>
        }
      />
      <div className="max-w-prose self-center">
        <div className="mt-10"></div>
        <form
          ref={formRef}
          onSubmit={submitBehindCaptcha}
          className="mx-5 flex max-w-prose flex-col space-y-8"
        >
          <input
            readOnly
            className="hidden"
            type="text"
            name="type"
            value="researchers"
          />
          <div>
            <p className="mb-1 text-lg font-semibold text-primary">
              First name
              <span className="ml-1 align-middle font-light">*</span>
            </p>
            <input
              className="w-full border-b border-b-black px-1 outline-none placeholder:text-sm placeholder:text-gray-400"
              placeholder="John"
              type="text"
              name="firstName"
              required
            ></input>
          </div>
          <div>
            <p className="mb-1 text-lg font-semibold text-primary">
              Last name<span className="ml-1 align-middle font-light">*</span>
            </p>
            <input
              className="w-full border-b border-b-black px-1 outline-none placeholder:text-sm placeholder:text-gray-400"
              placeholder="Doe"
              type="text"
              name="lastName"
              required
            ></input>
          </div>
          <div>
            <p className="mb-1 text-lg font-semibold text-primary">
              Work email
              <span className="ml-1 align-middle font-light">*</span>
            </p>
            <input
              className="w-full border-b border-b-black px-1 outline-none placeholder:text-sm placeholder:text-gray-400"
              placeholder="john.doe@gmail.com"
              type="email"
              name="email"
              required
            ></input>
          </div>
          <div>
            <p className="mb-1 text-lg font-semibold text-primary">
              Country/Region
              <span className="ml-1 align-middle font-light">*</span>
            </p>
            <input
              className="w-full border-b border-b-black px-1 outline-none placeholder:text-sm placeholder:text-gray-400"
              placeholder="Paris, France"
              type="text"
              name="countryOrRegion"
              required
            ></input>
          </div>
          <div>
            <p className="mb-1 text-lg font-semibold text-primary">
              Institution name
              <span className="ml-1 align-middle font-light">*</span>
            </p>
            <input
              className="w-full border-b border-b-black px-1 outline-none placeholder:text-sm placeholder:text-gray-400"
              placeholder="Scienta Lab"
              type="text"
              name="institution"
              required
            ></input>
          </div>
          <div>
            <p className="mb-1 text-lg font-semibold text-primary">
              Job title
              <span className="ml-1 align-middle font-light">*</span>
            </p>
            <input
              className="w-full border-b border-b-black px-1 outline-none placeholder:text-sm placeholder:text-gray-400"
              placeholder="Head of Rheumatology Department"
              type="text"
              name="jobTitle"
              required
            ></input>
          </div>
          <div>
            <p className="mb-1 text-lg font-semibold text-primary">
              Research area / Specialization
              <span className="ml-1 align-middle font-light">*</span>
            </p>
            <input
              className="w-full border-b border-b-black px-1 outline-none placeholder:text-sm placeholder:text-gray-400"
              placeholder="Rheumatology"
              type="text"
              name="researchArea"
              required
            ></input>
          </div>
          <div>
            <p className="mb-1 text-lg font-semibold text-primary">
              Message<span className="ml-1 align-middle font-light">*</span>
            </p>
            <textarea
              className="h-40 w-full resize-none rounded-md border border-black p-1 outline-none placeholder:text-sm placeholder:text-gray-400"
              placeholder="Looking forward to hearing from you"
              name="message"
              required
            ></textarea>
          </div>
          <div>
            <p className="mb-1 text-lg font-semibold text-primary">
              Sign up to our newsletter!
            </p>
            <CheckBox />
            <p className="mt-3 text-xs text-gray-400">
              By checking this box, I authorise Scienta Lab to collect my data
              for the purpose of sending the newsletter to which I wish to
              subscribe. I am aware that Scienta Lab has implemented a personal
              data protection policy which can be accessed{" "}
              <Link className="text-primary" to="/privacy-policy">
                here
              </Link>{" "}
              and that I may unsubscribe at any time via the unsubscribe link in
              each newsletter.
            </p>
          </div>
          <BtnOutline
            type="submit"
            color="secondary"
            content={formState === "loading" ? "Sending..." : "Send"}
            disabled={formState === "loading"}
          />
          <GoogleText />
          {recaptchaState === "error" && (
            <p className="text-sm text-red-400">
              Recaptcha script not loaded, please try to reload the page and
              submit the form again, or send an email at contact@scientalab.com
              to reach out.
            </p>
          )}
          {formState === "sent" && (
            <p className="text-sm">
              Thanks for the message. We'll be in touch shortly.
            </p>
          )}
          {formState === "error" && (
            <p className="text-sm text-red-400">
              Something went wrong, please send an email at
              contact@scientalab.com to reach out.
            </p>
          )}
        </form>
        <div className="mt-24"></div>
      </div>
    </Page>
  );
};

export default Researchers;
