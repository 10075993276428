import { CSSProperties, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { pagesInNav } from "..";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { useWindowDimensions } from "../hooks/useWindowDimensions";

import { ReactComponent as Close } from "../icons/Close.svg";
import Logo from "../icons/Logo";
import { ReactComponent as Menu } from "../icons/Menu.svg";

const Nav = () => {
  const [isDesktop] = useMediaQuery("(min-width: 865px)");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // We use useMemo here to be able to close the mobile nav if width changes. This way,
  // if users open the nav, increase the width to the point they switch to desktop nav, then
  // if they switch back to mobile, the nav will be closed.
  const isMobile = useMemo(() => {
    setIsOpen(false);
    return !isDesktop;
  }, [isDesktop]);

  return isMobile ? (
    <MobileNav
      openNavHandler={() => {
        // disable scroll behind mobile nav
        document.body.style.overflow = "hidden";
        setIsOpen(true);
      }}
      closeNavHandler={() => {
        // enable scroll behind mobile nav
        document.body.style.overflow = "auto";
        setIsOpen(false);
      }}
      isNavOpen={isOpen}
    />
  ) : (
    <DesktopNav />
  );
};

const MobileNav = ({
  openNavHandler,
  closeNavHandler,
  isNavOpen,
}: {
  openNavHandler: () => void;
  closeNavHandler: () => void;
  isNavOpen: boolean;
}) => {
  const { height } = useWindowDimensions();
  return (
    <nav className="relative z-50 flex h-20 w-full items-center justify-between p-5">
      <Link to="/">
        <Logo className="h-10 fill-white" />
      </Link>
      <button className="text-white" onClick={openNavHandler} name="Open nav">
        <Menu className="h-8" />
      </button>
      <div
        className={`${
          isNavOpen ? "translate-x-0" : "translate-x-full"
        } fixed top-0 left-0 mr-10 flex h-screen w-screen items-center justify-end  overflow-hidden bg-primary text-white transition-transform duration-300`}
        style={{ height }}
      >
        <button
          className="fixed top-5 right-5"
          onClick={closeNavHandler}
          name="Close nav"
        >
          <Close />
        </button>
        <ul className="spacing tracking-widest:border-b flex w-full flex-col space-y-8 px-10 text-right font-semibold uppercase leading-loose">
          {pagesInNav.map((page, idx) => (
            <li
              key={page.name}
              className={`${
                isNavOpen ? "translate-x-0" : "translate-x-full"
              } border-b transition-transform delay-[var(--delay)] duration-500`}
              style={{ "--delay": `${idx * 50}ms` } as CSSProperties}
            >
              <Link
                to={page.path}
                onClick={() => {
                  // enable scroll behind mobile nav before switching page
                  document.body.style.overflow = "auto";
                }}
              >
                <p>{page.name}</p>
              </Link>
            </li>
          ))}
        </ul>
        <Logo
          className="fixed bottom-5 right-5 h-10 fill-white"
          fillAffectsGradients
        />
      </div>
    </nav>
  );
};

const DesktopNav = () => (
  <nav className="relative z-50 flex h-20 w-full items-center justify-between p-5">
    <Link to="/">
      <Logo className="h-10 fill-white" />
    </Link>
    <ul className="flex space-x-5 ">
      {pagesInNav.map((page) => (
        <li
          key={page.name}
          className="text-white hover:text-tertiary hover:underline hover:decoration-2 hover:underline-offset-8"
        >
          <Link to={page.path}>
            <p>{page.name}</p>
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);

export default Nav;
