import { useEffect, useState } from "react";

type WindowDimensions = { width: number; height: number };
export const useWindowDimensions = (): WindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // Debouncing to avoid too many calls to setWindowDimensions
    const handleResize = debounce(() => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 100);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
};

// source: https://www.joshwcomeau.com/snippets/javascript/debounce/
// author: Joshua Comeau
const debounce = (callback: (...args: any[]) => void, wait: number) => {
  let timeoutId: number | undefined;
  return (...args: any[]) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};
