// Because we hide the captcha, we must include this text.
// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
export const GoogleText = () => (
  // Inline style is used to override the space-y-6 on the parent
  <p className="text-xs text-gray-400" style={{ marginTop: "8px" }}>
    This site is protected by reCAPTCHA and the Google
    <a
      className="text-primary"
      href="https://policies.google.com/privacy"
      target="_blank"
      rel="noreferrer"
    >
      {" "}
      Privacy Policy
    </a>{" "}
    and
    <a
      className="text-primary"
      href="https://policies.google.com/terms"
      target="_blank"
      rel="noreferrer"
    >
      {" "}
      Terms of Service
    </a>{" "}
    apply.
  </p>
);
