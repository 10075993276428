import { Link } from "react-router-dom";
import { BtnOutline } from "./Buttons";
import CheckBox from "./Checkbox";

const NewsletterForm = () => (
  <form
    action="https://amplifyapp.us21.list-manage.com/subscribe/post"
    className="space-y-8"
  >
    <input type="hidden" name="u" value="25524c498b3d63f698eceb47e" />
    <input type="hidden" name="id" value="ab1f44991f" />
    <p className="text-2xl font-bold text-primary" style={{ marginTop: 0 }}>
      Sign up to our newsletter !
    </p>
    <input
      className="w-full border-b border-b-black pl-1 outline-none placeholder:text-sm placeholder:text-gray-700"
      placeholder="First name"
      type="text"
      name="MERGE1"
      id="MERGE1"
    />
    <input
      className="w-full border-b border-b-black pl-1 outline-none placeholder:text-sm placeholder:text-gray-700"
      placeholder="Last name"
      type="text"
      name="MERGE2"
      id="MERGE2"
    />
    <input
      className="w-full border-b border-b-black pl-1 outline-none placeholder:text-sm placeholder:text-gray-700"
      placeholder="Email address"
      type="email"
      name="MERGE0"
      id="MERGE0"
    />
    <div className="space-y-2">
      <CheckBox required />
      <p className="text-xs text-gray-400">
        By checking this box, I authorise Scienta Lab to collect my data for the
        purpose of sending the newsletter to which I wish to subscribe. I am
        aware that Scienta Lab has implemented a personal data protection policy
        which can be accessed{" "}
        <Link className="text-primary" to="/privacy-policy">
          here
        </Link>{" "}
        and that I may unsubscribe at any time via the unsubscribe link in each
        newsletter.
      </p>
    </div>
    <BtnOutline type="submit" content="Subscribe" />
  </form>
);

export default NewsletterForm;
